<template>
  <div class="banner__info-buttons-wrap">
    <btn
      class="banner__info-buttons-btn banner__info-buttons-btn--white"
      data-aos="fade-left"
      data-aos-delay="900"
      data-aos-offset="0"
      @click="toggleModal"
    >
      trailer
      <BaseIcon :name="'play'"/>
    </btn>
    <TrailerPopup ref="trailerPopup" />
  </div>
</template>

<script>
import TrailerPopup from '@/components/popups/trailer-popup.vue'

export default {
  components: {
    TrailerPopup
  },
  methods: {
    toggleModal() {
      this.$refs.trailerPopup.open();
    }
  }
}
</script>

<style lang="scss" scoped>
.banner__info-buttons-wrap {
  flex-basis: 50%;
}
</style>
