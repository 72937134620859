var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("transition", { attrs: { name: "fade", mode: "out-in" } }, [
    _vm.getCoach
      ? _c(
          "div",
          { staticClass: "vue-coach-marks" },
          [
            _vm.canShow
              ? _c("div", {
                  staticClass: "vue-coach-marks__overlay",
                  style: {
                    ..._vm.getViewportPosition,
                    ..._vm.getAnimationStyles,
                  },
                })
              : _vm._e(),
            _c("transition", { attrs: { name: "fade", mode: "out-in" } }, [
              _vm.canShow
                ? _c(
                    "div",
                    {
                      ref: "card",
                      staticClass: "vue-coach-marks__card",
                      class: `vue-coach-marks__card--${_vm.card.type}`,
                      style: {
                        ..._vm.getCardPosition,
                        ..._vm.getAnimationStyles,
                      },
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "vue-coach-marks__card-content" },
                        [
                          _c(
                            "div",
                            { staticClass: "vue-coach-marks__card-title" },
                            [_vm._v(_vm._s(_vm.getCoach.title))]
                          ),
                          _c(
                            "div",
                            { staticClass: "vue-coach-marks__card-text" },
                            [_vm._v(_vm._s(_vm.getCoach.text))]
                          ),
                        ]
                      ),
                      _c(
                        "div",
                        { staticClass: "vue-coach-marks__card-buttons" },
                        [
                          _c("btn", {
                            staticClass: "vue-coach-marks__card-buttons-item",
                            attrs: {
                              type: "text-gray",
                              text: "Skip all&nbsp;&nbsp;&nbsp;&nbsp;»",
                            },
                            on: { click: _vm.onClickSkip },
                          }),
                          _c("btn", {
                            staticClass: "vue-coach-marks__card-buttons-item",
                            attrs: { type: "mint-dark", text: "GOT IT" },
                            on: { click: _vm.onClickNext },
                          }),
                        ],
                        1
                      ),
                    ]
                  )
                : _vm._e(),
            ]),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }