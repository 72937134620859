<template>
    <div class="fighters-list">
        <div class="fighters-list__bg-image">
            <y-line position="1" />
            <y-line position="6" />
        </div>

        <y-line position="1" />
        <y-line position="6" />

        <div class="container">
            <h2 class="section-header section-header--adaptive" data-aos="fade-up" data-aos-delay="0">
                Fighters
            </h2>

            <div class="description" data-aos="fade-up" data-aos-delay="100">
                <span>Get your unique character and customize his characteristics to defeat the enemy.</span>
            </div>

            <Loader v-if="!GET_CHARACTERS.length" class="nft-cards--loading"/>
            <div v-else class="nft-cards container">
                <card-wrapper
                    v-for="(character, i) in GET_CHARACTERS"
                    :key="`${character.name}_${i}`"
                    class="card-wrapper"
                    contentClass="fighters-list__content"
                    :tier="CHARACTERS[character.name] && CHARACTERS[character.name].tier"
                    :web-images="character.web_images"
                    v-bind="character"
                >
                    <template slot="content">
                        <div class="nft-card__title">
                            {{ character.name }}
                        </div>
                        <div class="nft-card__stats">
                            <tiny-stat
                                v-for="(attribute, i) of character.stats"
                                :key="i"
                                isSmall
                                class="nft-card__stats-item tiny-stat"
                                v-bind="attribute"
                            />
                        </div>
                    </template>
                </card-wrapper>
            </div>

            <div class="fighters-list__action">
                <btn
                    @click="goToUrl('/marketplace')"
                    class="fighters-list__action-btn"
                    type="icon-gradient"
                    text="Go to marketplace"
                    data-aos="zoom-in"
                    data-aos-delay="200"
                />
            </div>
        </div>
    </div>
</template>

<script>
import CardWrapper from '@/components/common/card-wrapper.vue'
import TinyStat from '@/components/common/tiny-stat.vue'
import YLine from '@/components/common/y-line.vue'
import Loader from '@/components/common/loader.vue'
import { CHARACTERS } from './Consts'
import { mapGetters } from "vuex";

export default {
    name: "FightersList",
    components: {
        CardWrapper,
        TinyStat,
        YLine,
        Loader,
    },
    data() {
        return {
            CHARACTERS,
        }
    },
    computed: {
        ...mapGetters(['GET_CHARACTERS']),
    },
}
</script>

<style lang="scss" scoped>

.card-wrapper {
    width: 243px;
    min-height: 414px;
}
.fighters-list {
    position: relative;

    .section-header {
        color: white;
    }

    &__bg-image {
        @include bg-image-tag-styles('../../assets/img/fighters-list-bg.jpg');
        top: -425px;
        max-height: 810px;
        z-index: 0;

        .line {
            background-color: #3F3F52 !important;
        }
    }

    &__action {
        text-align: center;
        padding-bottom: 150px;

        &-btn {
            color: white;
            border: none;

            &.active,
            &:hover {
                background-color: black;
                color: white;
            }
        }



        @media screen and (max-width: $bp_mb) {
            padding-bottom: 80px;
        }
    }
}

.line {
    z-index: -1;
}

.description {
    margin: 15px auto 0;
    max-width: 520px;
    color: white;
    font-size: 18px;
    font-weight: 500;
    text-align: center;
    text-transform: uppercase;
}

.nft-cards {
    display: flex;
    flex-wrap: wrap;
    gap: 3em;
    padding: 65px;
    justify-content: center;

    .nft-card__title {
        margin-top: 15px;
        font-size: 24px;
        text-align: center;
    }

    .nft-card__stats {
        transform: skew(4deg);
        padding: 10px 33px 25px;
    }

    .nft-card__content {
        background: linear-gradient(190deg, #1F213A 7.81%, #000000 202.46%)!important;
    }

    &--loading {
        margin-bottom: 5em;
    }

    @media screen and (max-width: 693px) {
        padding: 35px;
    }

    @media screen and (max-width: $bp_mb) {
        padding: 20px 10px;
        gap: 1em;

        .card-wrapper {
            width: 45%;
            min-height: 245px;
        }

        .nft-card__title {
            margin-top: 10px;
            font-size: 20px;
        }

        .nft-card__stats {
            padding: 8px 7px;
        }
    }

    @media screen and (max-width: 459px) {
        padding: 15px 7px;
    }
}

</style>
