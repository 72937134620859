<template>
  <div :class="['banner', {'banner--dense': events?.length}]">
    <fires-canvas :count="100" />
    
    <BgVideo/>

    <y-line class="banner__line" position="1" />
    <y-line class="banner__line" delay="3000" position="4" />

    <div class="banner__wrap">
      <div
        class="banner__gradient"
        data-aos="fade-left"
        data-aos-delay="800"
        data-aos-offset="0"
      >
    </div>
      <div :class="['banner__inner', 'container', {'banner__inner--dense': events?.length}]">

      <div class="banner__info">
        <Events
          v-if="events?.length"
          :banners="events"
        />

        <a
          :href="mainBanner?.url"
          class="banner__info-link"
          target="_blank"
        >
          <div
            class="banner__info-link-inner"
            data-aos="fade-left"
            data-aos-delay="200"
          >
            <picture>
              <img
                :src="mainBanner?.wide_image"
                alt="banner"
                class="banner__info-banner"
                data-aos="fade-left"
                data-aos-delay="0"
              >
            </picture>
          </div>
        </a>

        <div
          class="banner__info-title banner__info-title--gradient"
          data-aos="fade-left"
          data-aos-delay="300"
        >
          MetaFighter
        </div>
        <div
          class="banner__info-subtitle"
          data-aos="fade-left"
          data-aos-delay="600"
        >
          NFT Fighting Game
        </div>

        <div class="banner__info-buttons">
          <router-link
            to="/game"
            class="banner__info-buttons-btn banner__info-buttons-btn--gradient"
            data-aos="fade-left"
            data-aos-delay="800"
            data-aos-offset="0"
          >
            Play Now
          </router-link>
          
          <TrailerButton/>
        </div>

        <div class="banner__info-store-wrap">
          <p 
            class="banner__info-store-text"
            data-aos="fade-left"
            data-aos-delay="1100"
            data-aos-offset="0"
          >
            play on your device
          </p>

          <div class="banner__info-store-btns"> 
            <a
              href="https://play.google.com/store/apps/details?id=com.metafighter.game"
              target="_blank"
              class="banner__info-store-btn"
              data-aos="fade-left"
              data-aos-delay="1300"
              data-aos-offset="0"
            >
              <BaseIcon :name="'play-market'"/>
          </a>
            <a
              href="https://apps.apple.com/us/app/metafighter/id6448633678"
              target="_blank"
              class="banner__info-store-btn"
              data-aos="fade-left"
              data-aos-delay="1500"
              data-aos-offset="0"
            >
              <BaseIcon :name="'app-store'"/>
        </a>
          </div>
        </div>
      </div>
      </div>
    </div>
    <div class="banner__technologies">
      <div class="banner__technologies-item">
        <BaseIcon :name="'unity'"/>
        <p class="banner__technologies-title">Unity</p>
      </div>
      <div class="banner__technologies-item">
        <BaseIcon :name="'bnb-chain'"/>
        <p class="banner__technologies-title banner__technologies-title--uppercase"><span class="banner__technologies-title--bold">bnb</span> chain</p>
      </div>
      <div class="banner__technologies-item">
        <BaseIcon :name="'hack-a-chain'"/>
        <p class="banner__technologies-title">hack-a-chain</p>
      </div>
    </div>
  </div>
</template>

<script>
import YLine from '@/components/common/y-line.vue'
import FiresCanvas from '@/components/common/fires-canvas.vue'
import Events from './Events.vue'
import BgVideo from './BgVideo.vue'
import TrailerButton from './TrailerButton.vue'
import {mapGetters, mapActions} from 'vuex';

export default {
  name: "Banner",
  components: {
    YLine,
    FiresCanvas,
    Events,
    BgVideo,
    TrailerButton
  },
  mounted() {
    this.REQUEST_INFO_BANNER();
    this.REQUEST_EVENTS_BANNERS();
  },
  methods: {
    ...mapActions('events', ['REQUEST_INFO_BANNER', 'REQUEST_EVENTS_BANNERS'])
  },
  computed: {
    ...mapGetters('events', {
      mainBanner: 'GET_INFO_BANNER',
      events: 'GET_EVENTS_BANNERS'
    })
  } 
}
</script>

<style lang="scss">
.banner {
  padding-top: 110px;
  position: relative;
  overflow: hidden;
}

.banner.banner--dense {
  padding-top: 40px;
}

.banner__wrap {
  position: relative;
}

.banner__gradient {
  position: absolute;
  z-index: -1;
  right: 0;
  height: 100%;
  width: 1353px;

  background: linear-gradient(282deg, #04091B 40.52%, rgba(4, 9, 27, 0.84) 56.08%, rgba(4, 9, 27, 0.71) 66.91%, rgba(4, 9, 27, 0.44) 74.4%, rgba(4, 9, 27, 0.00) 79.98%);
  opacity: 0.95;
}

.banner__inner {
  position: static;
  padding-bottom: 40px;
}

.banner__inner.banner__inner--dense {
  padding-bottom: 20px;
}

.banner__line {
  background-color: #3F3F52 !important;
}

.banner__line.line {
  z-index: -2;
}

.banner__bg-image {
  @include bg-image-tag-styles('../../assets/img/banner-bg.jpg');
}

.banner__info {
  max-width: 675px;
  margin-left: auto;
  padding-top: 90px;
  font-family: "Raleway";
  padding-right: 90px;
}

.banner__info-title {
  margin-bottom: 10px;

  font-family: "Tektur";
  font-size: 87px;
  font-weight: 800;
  line-height: normal;
  text-transform: uppercase;
  text-align: center;
}

.banner__info-title--gradient {
  background: linear-gradient(92deg, #FFCC13 2.36%, #FF9509 48.1%, #FFC812 99.25%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.banner__info-subtitle {
  margin-bottom: 50px;

  font-size: 58px;
  font-weight: 900;
  line-height: normal;
  color: #fff;
  text-transform: uppercase;
  text-align: center;
}

.banner__info-text {
  color: #fff;
  font-size: 49px;
  text-transform: uppercase;
}

.banner__info-buttons {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  margin-bottom: 66px;

  &:empty {
    display: none;
  }
}

.banner__info-buttons-item {
  margin-right: 18px;
  flex-grow: 1;

  &:last-child {
    margin-right: 0;
  }
}

.banner__info-buttons-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 64px;
  gap: 10px;
  padding: 12px 40px;
  
  font-family: "Tektur";
  font-size: 17px;
  font-weight: 500;
  line-height: normal;
  text-transform: uppercase;
  transition: 300ms ease-in;

  &--gradient {
    flex-basis: 50%;
    color: #000;
    background: linear-gradient(90deg, #FF9509 0%, #FFCC13 100%);

    &:hover {
      color: #fff;
      background: #000;
    }
  }

  &--white {
    color: #fff;
    border: 2px solid #fff;

    svg {
      margin-right: -10px;
    }

    &:hover {
      color: #000;
      border: none;
      background-color: #fff;
    }
  }
}

.banner__info-link {
  display: flex;
  margin: 0 auto;
  margin-bottom: 40px;
  max-width: 463px;
}

.banner__info-link-inner {
  border: 3px solid #fff;
}

.banner__info-banner {
  display: block;
  max-width: 100%;
}

.banner__info-store-wrap {
  display: flex;
  flex-direction: column;
}

.banner__info-store-text {
  margin-right: 10px;

  text-align: right;
  font-family: "Raleway";
  font-size: 16px;
  font-weight: 700;
  line-height: normal;
  text-transform: capitalize;
  color: #fff;
}

.banner__info-store-btns {
  display: flex;
  gap: 10px;
  align-self: flex-end;
  margin-top: 20px;
}

.banner__info-store-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 22px;
  min-height: 50px;
  min-width: 153px;
  border-radius: 10px;
  border: 2px solid rgba(255, 255, 255, 0.40);
  transition: 300ms ease-in;
  color: #fff;

  cursor: pointer;

  &:hover {
    color: #000;
    background-color: #fff;
  }

  svg {
    width: 105px;
  }
}

.banner__technologies {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 76px;
  padding: 25px 30px 25px 90px;
  background-color: #04091B;
}

.banner__technologies-item {
  display: flex;
  align-items: center;
  gap: 10px;
  color: #fff;

  svg {
    width: 30px;
    height: 30px;
  }
}

.banner__technologies-title {
  font-family: "Raleway";
  font-size: 16px;
  font-weight: 600;
  line-height: normal;

  &--bold {
    font-weight: 800;
  }

  &--uppercase {
    text-transform: uppercase;
  }
}

@media screen and (max-width: 1820px) { // 1680
  .banner {
    padding-top: 100px;
  }

  .banner__info {
    padding-top: 80px;
    padding-right: 110px;
    max-width: 580px;
  }

  .banner__info-link {
    margin-bottom: 30px;
    max-width: 410px;
  }

  .banner__info-title {
    font-size: 70px;
  }

  .banner__info-subtitle {
    margin-bottom: 40px;
    font-size: 46px;
  }

  .banner__info-buttons {
    margin-bottom: 50px;
  }

  .banner__info-buttons-btn {
    min-height: 58px;
  }

  .banner__info-store-btn {
    padding: 6px 20px;
    min-height: 46px;
    min-width: 120px;
  }

  .banner__info-store-btn svg {
    width: 90px;
  }
}

@media screen and (max-width: 1679px) { // 1536
  .banner {
    padding-top: 90px;
  }

  .banner__inner {
    padding-bottom: 35px;
  }

  .banner__info {
    padding-top: 55px;
    padding-right: 120px;
    max-width: 564px;
  }

  .banner__info-link {
    margin-bottom: 30px;
    max-width: 390px;
  }

  .banner__info-title {
    margin-bottom: 10px;
    font-size: 66px;
  }

  .banner__info-subtitle {
    font-size: 44px;
    margin-bottom: 40px;
  }

  .banner__info-buttons {
    margin-bottom: 35px;
  }

  .banner__info-buttons-btn {
    min-height: 54px;
    padding: 9px 30px;
    font-size: 15px;
  }

  .banner__info-store-btns {
    margin-top: 15px;
  }

  .banner__info-store-text {
    font-size: 15px;
  }
}

@media screen and (max-width: 1535px) { // 1440
  .banner__info {
    padding-top: 70px;
    max-width: 590px;
  }

  .banner__info-link {
    max-width: 406px;
  }

  .banner__info-title {
    font-size: 70px;
  }

  .banner__info-subtitle {
    font-size: 46px;
    margin-bottom: 45px;
  }
}

@media screen and (max-width: 1439px) { // 1366
  .banner {
    padding-top: 80px;
  }

  .banner__inner {
    padding-bottom: 30px;
  }

  .banner__info {
    padding-top: 50px;
    padding-right: 110px;
    max-width: 538px;
  }

  .banner__info-link {
    margin-bottom: 25px;
    max-width: 346px;
  }

  .banner__info-title {
    margin-bottom: 5px;
    font-size: 64px;
  }

  .banner__info-subtitle {
    font-size: 42px;
    margin-bottom: 40px;
  }

  .banner__info-buttons-btn {
    min-height: 50px;
    font-size: 14px;
  }

  .banner__info-store-btns {
    margin-top: 10px;
  }

  .banner__info-store-text {
    font-size: 14px;
  }

  .banner__info-store-btn {
    padding: 6px 20px;
    min-height: 40px;
    min-width: 110px;
  }

  .banner__info-store-btn svg {
    width: 76px;
  }

  .banner__technologies {
    gap: 60px;
    padding: 20px 30px 20px 90px;
  }

  .banner__technologies-title {
    font-size: 14px;
  }

  .banner__technologies-item {
    svg {
      width: 22px;
      height: 22px;
    }
  }
}

@media screen and (max-width: 1365px) { // 1280
  .banner__info {
    padding-top: 45px;
    max-width: 526px;
  }

  .banner__info-link {
    max-width: 330px;
  }

  .banner__info-title {
    font-size: 60px;
  }

  .banner__info-subtitle {
    font-size: 40px;
  }

  .banner__info-buttons-btn {
    min-height: 46px;
  }

  .banner__info-store-text {
    font-size: 13px;
  }

  .banner__info-store-btn {
    padding: 6px 14px;
    min-height: 36px;
    min-width: 100px;
  }

  .banner__info-store-btn svg {
    width: 70px;
  }

  .banner__technologies {
    gap: 55px;
    padding: 14px 30px 14px 60px;
  }

  .banner__technologies-title {
    font-size: 13px;
  }

  .banner__technologies-item {
    svg {
      width: 20px;
      height: 20px;
    }
  }
}

@media screen and (max-width: 1279px) { // 1024
  .banner__inner {
    padding-bottom: 30px;
  }

  .banner__info {
    padding-top: 50px;
    padding-right: 70px;
    max-width: 485px;
  }

  .banner__info-link {
    margin-bottom: 25px;
    max-width: 350px;
  }

  .banner__info-title {
    margin-bottom: 5px;
    font-size: 62px;
  }

  .banner__info-subtitle {
    font-size: 41px;
    margin-bottom: 30px;
  }

  .banner__info-buttons {
    margin-bottom: 30px;
  }

  .banner__info-buttons-btn {
    min-height: 50px;
    padding: 11px 30px;
  }

  .banner__info-store-btns {
    margin-top: 15px;
  }

  .banner__info-store-text {
    font-size: 15px;
  }

  .banner__info-store-btn {
    padding: 10px 22px;
    min-height: 42px;
    min-width: 140px;
  }

  .banner__info-store-btn svg {
    width: 90px;
  }

  .banner__technologies {
    padding: 20px 30px 20px 85px;
  }

  .banner__technologies-title {
    font-size: 15px;
  }

  .banner__technologies-item {
    svg {
      width: 24px;
      height: 24px;
    }
  }
}

@media screen and (max-width: 1023px) {
  .banner {
    padding-top: 75px;
  }

  .banner__inner {
    padding-bottom: 40px;
  }

  .banner__info {
    padding-top: 60px;
    padding-right: 85px;
    min-width: 486px;
    max-width: fit-content;
  }

  .banner__info-link {
    max-width: 410px;
    margin-bottom: 40px;
  }

  .banner__info-title {
    margin-bottom: 10px;
    font-size: 70px;
  }

  .banner__info-subtitle {
    margin-bottom: 40px;
    font-size: 48px;
  }

  .banner__info-buttons {
    margin-bottom: 50px;
  }

  .banner__info-buttons-btn {
    min-height: 64px;
    font-size: 17px;
  }

  .banner__info-store-btn {
    padding: 10px 22px;
    min-height: 50px;
    min-width: 153px;

    svg {
      width: 105px;
    }
  }

  .banner__info-store-btns {
    margin-top: 20px;
  }

  .banner__info-store-text {
    font-size: 16px;
  }

  .banner__technologies {
    gap: 76px;
    padding: 23px 30px 23px 85px;
  }
}

@media screen and (max-width: ($bp_desktop - 1px)) {
  .banner {
    padding-top: 80px;
    background-color: #04091B;
  }

  .banner__gradient {
    content: none;
  }

  .banner__info-link {
    margin-bottom: 30px;
  }

  .banner__info-subtitle {
    margin-bottom: 30px;
    font-size: 46px;
  }

  .banner__info {
    min-width: 465px;
    padding: 0;
    margin: 0 auto;
    margin-top: -100px;
  }

  .banner__info-buttons-btn {
    padding: 11px 40px;
    min-height: 50px;
  }

  .banner__info-store-wrap {
    align-items: center;
  }

  .banner__info-store-text {
    margin-right: 0;
    font-weight: 500;
    font-size: 15px;
  }

  .banner__info-store-btns {
    margin-top: 10px;
    align-self: auto;
  }

  .banner__info-store-btn {
    padding: 7px 20px;
    min-height: 40px;
    min-width: 130px;

    svg {
      width: 85px;
    }
  }

  .banner__technologies {
    gap: 70px;
    padding: 22px 30px 22px 85px;
    border-top: 1px solid #292E3D;
    border-bottom: 1px solid #292E3D;
  }
}

@media screen and (max-width: ($bp_mb - 1px)) {
  .banner {
    padding-top: 60px;
  }

  .banner__inner {
    position: relative;
    padding-bottom: 0;
  }

  .banner__info {
    min-width: unset;
    margin-top: -55px;
    padding-bottom: 20px;
  }

  .banner__info-title {
    font-size: 42px;
  }

  .banner__info-subtitle {
    font-size: 27px;
  }

  .banner__info-link {
    max-width: 250px;
  }

  .banner__info-buttons {
    gap: 10px;
  }

  .banner__info-buttons-btn {
    padding: 11px 10px;
    font-size: 15px;
  }

  .banner__technologies {
    column-gap: 30px;
    row-gap: 10px;
    flex-wrap: wrap;
    padding: 10px 20px;
  }

  .banner__technologies-title {
    font-size: 14px;
  }

  .banner__technologies-item {
    svg {
      width: 20px;
    }
  }
}
</style>
