var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "main" },
    [
      _vm.isLoading
        ? _c("Preloader")
        : _c(
            "div",
            [
              _c("socials", { attrs: { type: "fixed" } }),
              _c("banner"),
              _c("fighters"),
              _c("fighters-list"),
              _c("meta-fights"),
              _c("partners"),
              _vm.showMarks
                ? _c("vue-coach-marks", { attrs: { marks: _vm.marks } })
                : _vm._e(),
            ],
            1
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }