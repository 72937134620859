var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "youtube-video" },
    [
      _c("i", { staticClass: "youtube-video__layout" }),
      _c("icon-btn", {
        staticClass: "youtube-video__btn",
        attrs: { size: _vm.btnSize },
        on: { click: _vm.onPlay },
      }),
      _c(
        "div",
        {
          staticClass: "youtube-video r16x9",
          style: {
            backgroundImage: `url(${_vm.getPreview})`,
          },
        },
        [
          _vm.getCanPlay
            ? _c("iframe", {
                attrs: {
                  width: "560",
                  height: "315",
                  src: _vm.getVideoURL,
                  frameborder: "0",
                  allowfullscreen: "",
                  allow:
                    "accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture",
                },
              })
            : _vm._e(),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }