<template>
  <div class="partners">
    <y-line position="6" />

    <div class="container">
      <h2
        class="section-header section-header--adaptive"
        data-aos="fade-up"
        data-aos-delay="50"
      >
        Partners
      </h2>

      <div
        class="partners-slider"
        data-aos="fade-up"
        data-aos-delay="150"
        data-aos-duration="850"
        data-aos-offset="200"
      >
        <vue-slick-carousel v-bind="getSliderSettings">
          <div
            v-for="(partner, i) in partners"
            :key="i"
          >
            <div class="partners-slider__item">
              <img
                loading="lazy"
                :src="require(`@/assets/img/partners/new/${partner.img}`)"
                class="partners-slider__img"
                alt="parner slider logotype"
              >
            </div>
          </div>
        </vue-slick-carousel>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import YLine from '@/components/common/y-line.vue'

export default {
  name: "Partners",
  components: {
    YLine,
  },
  data() {
    return {
      partners: [
        {
          img: '1.png',
        },
        {
          img: '2.png',
        },
        {
          img: '3.png',
        },
        {
          img: '4.png',
        },
        {
          img: '5.png',
        },
        {
          img: '6.png',
        },
        {
          img: '7.png',
        },
        {
          img: '8.png',
        },
        {
          img: '9.png',
        },
        {
          img: '10.png',
        },
        {
          img: '11.png',
        },
        {
          img: '12.png',
        },
        {
          img: '13.png',
        },
        {
          img: '14.png',
        },
        {
          img: '15.png',
        },
        {
          img: '16.png',
        },
        {
          img: '17.png',
        },
        {
          img: '18.png',
        },
        {
          img: '19.png',
        },
        {
          img: '20.png',
        },
        {
          img: '21.png',
        },
        {
          img: '22.png',
        },
        {
          img: '23.png',
        },
        {
          img: '24.png',
        },
        {
          img: '25.png',
        },
        {
          img: '26.png',
        },
        {
          img: '27.png',
        },
        {
          img: '28.png',
        },
        {
          img: '29.png',
        },
        {
          img: '30.png',
        },
        {
          img: '31.png',
        },
        {
          img: '32.png',
        },
        {
          img: '33.png',
        },
        {
          img: '34.png',
        },
        {
          img: '35.png',
        },
        {
          img: '36.png',
        },
        {
          img: '37.png',
        },
        {
          img: '38.png',
        },
        {
          img: '39.png',
        },
        {
          img: '40.png',
        },
        {
          img: '41.png',
        },
        {
          img: '42.png',
        },
        {
          img: '43.png',
        },
        {
          img: '44.png',
        },
        {
          img: '45.png',
        },
        {
          img: '46.png',
        },
        {
          img: '47.png',
        },
        {
          img: '48.png',
        },
        {
          img: '49.png',
        },
        {
          img: '50.png',
        }
      ],
    }
  },
  computed: {
    ...mapGetters([
      'GET_IS_MOBILE',
    ]),

    getSliderSettings() {
      let settigns = {
        arrows: false,
        dots: true,
        autoplay: true,
        rows: 3,
        slidesPerRow: 4,
        slidesToShow: 1,
      }

      if (this.GET_IS_MOBILE) {
        settigns = {
          ...settigns,
          slidesPerRow: 1,
          centerMode: true,
          centerPadding: '20%',
          adaptiveHeight: true,
        }
      }

      return settigns
    },
  },
}
</script>

<style lang="scss">

.partners {
  position: relative;
  z-index: 1;

  padding: (90px + 76px) 65px 145px;
}

.partners-slider {
  margin-top: 60px;
}

.partners-slider__item {
  height: 80px;
  padding: 10px 30px;

  display: flex;
  align-items: center;
  justify-content: center;
}

.partners-slider__img {
  max-width: 100%;
  max-height: 100%;

  pointer-events: none;
}

@media screen and (max-width: $bp_mb) {
  .partners {
    padding: 85px 0 90px;
  }
  .partners-slider__item {
    padding: 0 20px;
    margin: 10px 0;
    // height: auto;
  }
}


</style>
