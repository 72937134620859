var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "banner__info-buttons-wrap" },
    [
      _c(
        "btn",
        {
          staticClass:
            "banner__info-buttons-btn banner__info-buttons-btn--white",
          attrs: {
            "data-aos": "fade-left",
            "data-aos-delay": "900",
            "data-aos-offset": "0",
          },
          on: { click: _vm.toggleModal },
        },
        [_vm._v(" trailer "), _c("BaseIcon", { attrs: { name: "play" } })],
        1
      ),
      _c("TrailerPopup", { ref: "trailerPopup" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }