<template>
  <canvas ref="canvas" class="canvas" :width="width" :height="height" />
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: "FiresCanvas",
  props: {
    count: { type: Number, default: 500 },
  },

  data() {
    return {
      canvas: null,
      ctx: null,
      width: window.innerWidth,
      height: window.innerHeight,
      particles: {},
      particleIndex: 0,
      isInViewport: false,
      timer: null,
    }
  },

  computed: {
    ...mapGetters([
      'GET_WINDOW_WIDTH',
      'GET_WINDOW_HEIGHT',
    ]),
  },

  watch: {
    GET_WINDOW_WIDTH: {
      immediate: true,
      handler() {
        this.onWindowChange()
      },
    },
    GET_WINDOW_HEIGHT: {
      immediate: true,
      handler() {
        this.onWindowChange()
      },
    },
  },

  mounted() {
    this.start()

    this.timer = setInterval(this.checkIsInViewport, 300)
  },

  beforeDestroy() {
    clearInterval(this.timer)
  },

  methods: {
    onWindowChange() {
      this.init()
    },

    start() {
      this.init()
      this.loop()
    },

    init() {
      this.canvas = this.$refs.canvas
      if (!this.canvas) {
        return
      }

      this.ctx = this.canvas.getContext('2d')
      const parent = this.canvas?.parentElement

      this.canvas.width = parent.clientWidth
      this.canvas.height = parent.clientHeight

      this.particles = {}
      this.particleIndex = 0

      for (var i = 1; i < this.count; i++) {
        this.addParticle(i)
      }
    },

    loop() {
      if (!this.canvas || !Object.keys(this.particles).length) {
        return
      }

      window.requestAnimationFrame(this.loop)
      if (this.isInViewport) {
        this.drawParticle()
      }
    },

    drawParticle() {
      this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height)

      for (var el in this.particles) {
        this.draw(this.particles[el])
      }
    },

    getCreatedParticleParams() {
      const shadeR = Math.floor(Math.random() * 255 + 150) + 50
      const shadeG = Math.floor(Math.random() * 150) + 50
      const shadeB = Math.floor(Math.random() * 0)

      const particleParams = {
        x: this.getRandomX(),
        y: this.getRandomY(),
        vx: Math.random() * 10 - 5,
        vy: Math.random() * 10 - 5,
        gravity: 0.3,
        life: 0,
        maxLife: Math.random() * 30,
        color: `rgba(${shadeR}, ${shadeG}, ${shadeB}, ${Math.random() * 0.7})`,
        size: Math.random() * 3,
      }

      return particleParams
    },

    getRandomX() {
      return Math.random() * this.canvas.width
    },

    getRandomY() {
      return Math.random() * this.canvas.height / 1.3
    },

    draw(particleParams) {
      particleParams.x += particleParams.vx
      particleParams.y += particleParams.vy

      if (Math.random() < 0.1) {
        particleParams.vx = Math.random() * 10 - 5
        particleParams.vy = -2
      }

      if (++particleParams.life >= particleParams.maxLife) {
        this.particleLifeIsOver(particleParams.id)
      }

      if (particleParams.y + particleParams.size < 0 || particleParams.y > this.canvas.height) {
        this.particleIsOut(particleParams.id)
      }

      if (particleParams.x + particleParams.size < 0 || particleParams.x > this.canvas.width) {
        this.particleIsOut(particleParams.id)
      }

      this.ctx.beginPath()
      this.ctx.arc(particleParams.x, particleParams.y, particleParams.size, 0, 2 * Math.PI, false)
      this.ctx.fillStyle = particleParams.color
      this.ctx.fill()
    },

    addParticle(id) {
      const particle = this.getCreatedParticleParams()
      this.particles[id] = { ...particle, id }
    },

    particleLifeIsOver(id) {
      this.addParticle(id)
    },

    particleIsOut(id) {
      this.addParticle(id)
    },

    checkIsInViewport() {
      this.isInViewport = this.isElementInViewport(this.$refs.canvas)
    },

    isElementInViewport (el) {
      var rect = el.getBoundingClientRect()
      return (
          rect.bottom >= 0 &&
          rect.top <= (window.innerHeight || document.documentElement.clientHeight)
      );
    }
  },
}
</script>

<style lang="scss" scoped>
.canvas {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  pointer-events: none;
}
</style>
