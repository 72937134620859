var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "banner__bg-video-wrap" }, [
    _vm.GET_IS_MOBILE
      ? _c(
          "video",
          {
            staticClass: "banner__bg-video",
            attrs: { autoplay: "", loop: "", muted: "" },
            domProps: { muted: true },
          },
          [
            _c("source", {
              attrs: {
                src: require("@/assets/video/banner-320x290.mp4"),
                type: "video/mp4",
              },
            }),
          ]
        )
      : _vm.GET_IS_TABLET
      ? _c(
          "video",
          {
            staticClass: "banner__bg-video",
            attrs: { autoplay: "", loop: "", muted: "" },
            domProps: { muted: true },
          },
          [
            _c("source", {
              attrs: {
                src: require("@/assets/video/banner-640x370.mp4"),
                type: "video/mp4",
              },
            }),
          ]
        )
      : _c(
          "video",
          {
            staticClass: "banner__bg-video",
            attrs: { autoplay: "", loop: "", muted: "" },
            domProps: { muted: true },
          },
          [
            _c("source", {
              attrs: {
                src: require("@/assets/video/banner-1920x890.mp4"),
                type: "video/mp4",
              },
            }),
          ]
        ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }