// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".youtube-video__layout {\n  position: absolute;\n  top: 0;\n  left: 0;\n  right: 0;\n  bottom: 0;\n  z-index: 1;\n  background-color: rgba(0, 0, 0, 0.2);\n}\n.youtube-video__btn {\n  position: absolute;\n  top: 0;\n  left: 0;\n  right: 0;\n  bottom: 0;\n  z-index: 2;\n  margin: auto;\n}\n\n/* adaptive video */\n.youtube-video {\n  background: center center no-repeat;\n  background-size: cover;\n  position: relative;\n}\n.youtube-video.r16x9 {\n  padding-bottom: 56.25%;\n  /* 16:9 */\n}\n.youtube-video.r4x3 {\n  padding-bottom: 75%;\n  /* 4:3 */\n}\n.youtube-video.r3x2 {\n  padding-bottom: 66.66%;\n  /* 3:2 */\n}\n.youtube-video.r8x5 {\n  padding-bottom: 62.5%;\n  /* 8:5 */\n}\n.youtube-video iframe {\n  position: absolute;\n  z-index: 2;\n  top: 0;\n  left: 0;\n  width: 100%;\n  height: 100%;\n}\n.youtube-video iframe[src=\"\"] {\n  display: none;\n}", ""]);
// Exports
exports.locals = {
	"bp_mb_xs": "430px",
	"bp-mb": "640px",
	"bp-laptop": "1630px",
	"bp-desktop": "992px"
};
module.exports = exports;
