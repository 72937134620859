var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "meta-fights" },
    [
      _c("fires-canvas", { attrs: { count: 100 } }),
      _c("y-line", {
        staticClass: "meta-fights__line meta-fights__line--2",
        attrs: { position: "1" },
      }),
      _c("y-line", {
        staticClass: "meta-fights__line meta-fights__line--6",
        attrs: { position: "6" },
      }),
      _c("i", { staticClass: "meta-fights__bg-image" }),
      _c(
        "div",
        { staticClass: "meta-fights__inner container" },
        [
          !_vm.GET_IS_MOBILE ? [_vm._m(0), _vm._m(1)] : _vm._e(),
          _c("div", { staticClass: "meta-fights__top" }, [
            _vm._m(2),
            _c(
              "ul",
              { staticClass: "meta-fights__list" },
              _vm._l(_vm.list, function (item, i) {
                return _c(
                  "li",
                  {
                    key: i,
                    staticClass: "meta-fights__list-item",
                    attrs: {
                      "data-aos": "fade-left",
                      "data-aos-delay": 50 * i,
                    },
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "meta-fights__list-media",
                        attrs: {
                          "data-aos": "zoom-in",
                          "data-aos-delay": 50 * i + 100,
                        },
                      },
                      [
                        _c("img", {
                          attrs: {
                            loading: "lazy",
                            src: require(`../../assets/img/icons/${item.icon}`),
                            alt: "icon",
                          },
                        }),
                      ]
                    ),
                    _c("div", { staticClass: "meta-fights__list-info" }, [
                      _c(
                        "div",
                        { staticClass: "meta-fights__list-info-title" },
                        [_vm._v(_vm._s(item.title))]
                      ),
                      _c(
                        "div",
                        { staticClass: "meta-fights__list-info-text" },
                        [_vm._v(_vm._s(item.text))]
                      ),
                    ]),
                  ]
                )
              }),
              0
            ),
          ]),
          _vm.GET_IS_MOBILE
            ? _c("div", { staticClass: "meta-fights__img-wrapper" }, [
                _c("img", {
                  staticClass: "meta-fights__img meta-fights__img--bg",
                  attrs: {
                    loading: "lazy",
                    src: require("@/assets/img/meta-fighters-bg-mobile.svg?inline"),
                    alt: "bg",
                  },
                }),
                _c("img", {
                  staticClass: "meta-fights__img meta-fights__img--1",
                  attrs: {
                    loading: "lazy",
                    src: require("@/assets/img/meta-fighters-img-1.png"),
                    alt: "fighter",
                  },
                }),
              ])
            : _vm._e(),
          _c("div", { staticClass: "meta-fights__bottom" }, [
            _vm._m(3),
            _c(
              "div",
              {
                staticClass: "meta-fights__action",
                attrs: { "data-coach-mark": "main-pancakeswap-dextool-btns" },
              },
              [
                _c("btn", {
                  staticClass: "meta-fights__action-btn",
                  attrs: {
                    type: "icon-gradient",
                    icon: "pancakeswap-img.png",
                    title: "PancakeSwap",
                    "data-aos": "zoom-in",
                    "data-aos-delay": "200",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.goTo(_vm.LINKS.PANCAKE)
                    },
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "meta-fights__action" },
              [
                _c("btn", {
                  staticClass: "meta-fights__action-btn",
                  attrs: {
                    type: "icon-gradient",
                    icon: "dextool.png",
                    title: "Dextool",
                    "data-aos": "zoom-in",
                    "data-aos-delay": "200",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.goToBlank(_vm.LINKS.DEX)
                    },
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              {
                staticClass: "meta-fights__action",
                attrs: { "data-coach-mark": "main-pancakeswap-dextool-btns" },
              },
              [
                _c("btn", {
                  staticClass: "meta-fights__action-btn",
                  attrs: {
                    type: "icon-gradient",
                    icon: "digifinex.png",
                    title: "Digifinex",
                    "data-aos": "zoom-in",
                    "data-aos-delay": "200",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.goTo(_vm.LINKS.DIGIFINEX)
                    },
                  },
                }),
              ],
              1
            ),
          ]),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("picture", [
      _c("source", {
        attrs: {
          srcset: require("@/assets/img/meta-fighters-img-1.webp"),
          type: "image/webp",
        },
      }),
      _c("source", {
        attrs: {
          srcset: require("@/assets/img/meta-fighters-img-1.png"),
          type: "image/png",
        },
      }),
      _c("img", {
        staticClass: "meta-fights__img meta-fights__img--1",
        attrs: {
          loading: "lazy",
          src: require("@/assets/img/meta-fighters-img-1.png"),
          alt: "fighter",
          "data-aos": "fade-right",
          "data-aos-duration": "500",
        },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("picture", [
      _c("source", {
        attrs: {
          srcset: require("@/assets/img/meta-fighters-img-2.webp"),
          type: "image/webp",
        },
      }),
      _c("source", {
        attrs: {
          srcset: require("@/assets/img/meta-fighters-img-2.png"),
          type: "image/png",
        },
      }),
      _c("img", {
        staticClass: "meta-fights__img meta-fights__img--2",
        attrs: {
          loading: "lazy",
          src: require("@/assets/img/meta-fighters-img-2.png"),
          alt: "fighter",
          "data-aos": "fade-left",
          "data-aos-delay": "100",
        },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "h2",
      {
        staticClass:
          "meta-fights__top-header section-header section-header--adaptive",
      },
      [
        _c(
          "div",
          {
            staticClass: "section-header--fz-41",
            attrs: { "data-aos": "fade-left", "data-aos-delay": "50" },
          },
          [_vm._v("TOKEN UTILITY IN THE")]
        ),
        _c(
          "span",
          {
            staticClass: "section-header--gradient section-header--fz-68",
            attrs: { "data-aos": "fade-left", "data-aos-delay": "200" },
          },
          [_vm._v("metaverse")]
        ),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "h2",
      {
        staticClass:
          "meta-fights__bottom-header section-header section-header--adaptive",
        attrs: { "data-aos": "fade-up", "data-aos-delay": "0" },
      },
      [
        _c(
          "span",
          { staticClass: "section-header--gradient section-header--fz-58" },
          [_vm._v("GET YOUR $MF")]
        ),
        _c("div", [_vm._v("tokens")]),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }