<template>
  <div>
    <div
      class="banner__events"
      data-aos="fade-left"
      data-aos-delay="200"
    >
      <a
        v-for="banner in banners"
        :key="banner.id"
        :href="banner.url"
        class="banner__event"
      >
        <img
          :src="banners.length > 1 ? banner.banner : (banner.wide_banner || banner.banner)"
          alt="event"
          class="banner__event-image"
        >
      </a>
    </div>
  </div>
</template>

<script>
export default {
  props: ['banners']
}
</script>

<style lang="scss">
.banner__events {
  height: 106px;
  max-width: 463px;
  margin: 0 auto 20px;
  display: flex;
  gap: 20px;

  @media screen and (max-width: 1820px) {
    max-width: 410px;
  }
  
  @media screen and (max-width: 1679px) {
    max-width: 390px;
  }
  
  @media screen and (max-width: 1535px) {
    max-width: 406px;
  }
  
  @media screen and (max-width: 1439px) {
    max-width: 346px;
  }
  
  @media screen and (max-width: 1365px) {
    max-width: 330px;
  }
  
  @media screen and (max-width: 1279px) {
    max-width: 350px;
  }
  
  @media screen and (max-width: 1023px) {
    max-width: 410px;
  }

  @media screen and (max-width: ($bp_mb - 1px)) {
    max-width: 250px;
  }
}

.banner__event {
  flex: 1;
  display: block;
  height: 100%;
  border: 3px solid white;
}

.banner__event-image {
  object-fit: cover;
  height: 100%;
  width: 100%;
}
</style>
