<template>
  <transition
    name="fade"
    mode="out-in"
  >
    <div v-if="getCoach" class="vue-coach-marks">
      <div
        v-if="canShow"
        class="vue-coach-marks__overlay"
        :style="{
          ...getViewportPosition,
          ...getAnimationStyles,
        }"
      />

      <transition
          name="fade"
          mode="out-in"
      >
        <div
            v-if="canShow"
            :style="{
                ...getCardPosition,
                ...getAnimationStyles,
            }"
            ref="card"
            class="vue-coach-marks__card"
            :class="`vue-coach-marks__card--${card.type}`"
        >
            <div class="vue-coach-marks__card-content">
              <div class="vue-coach-marks__card-title">{{ getCoach.title }}</div>
              <div class="vue-coach-marks__card-text">{{ getCoach.text }}</div>
            </div>
            <div class="vue-coach-marks__card-buttons">
              <btn class="vue-coach-marks__card-buttons-item" type="text-gray" text="Skip all&nbsp;&nbsp;&nbsp;&nbsp;»" @click="onClickSkip" />
              <btn class="vue-coach-marks__card-buttons-item" type="mint-dark" text="GOT IT" @click="onClickNext" />
            </div>
        </div>
      </transition>
    </div>
  </transition>
</template>

<script>
import { mapMutations, mapGetters } from "vuex";
export default {
  name: "VueCoachMarks",
  props: {
    marks: { type: Array, default: () => [] },
  },
  data() {
    return {
      htmlDataKeys: {
        mark: 'data-coach-mark',
        name: 'data-name',
      },
      currentMarkIndex: 0,
      viewport: {
        width: 0,
        height: 0,
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
      },
      card: {
        left: 0,
        top: 0,
        type: '',
      },

      coachMarks: [],
      isScrolling: false,
      showMarks: false,
    }
  },
  async mounted() {
    await this.delay(500)
    this.initMarks()
    this.draw()

    window.addEventListener('resize', this.draw)
  },

  beforeDestroy() {
    this.stop()
  },

  computed: {
    ...mapGetters([
      'GET_WATCHED_MARKS',
      'IS_MARK_WATCHED',
      'GET_IS_COOKIE_ALERT_SHOW',
      'GET_IS_MOBILE',
    ]),

    getViewportPosition() {
      const top = this.viewport.top
      const left = this.viewport.left
      const right = this.viewport.right
      const bottom = this.viewport.bottom
      let value = `polygon(
        0 0,
        100% 0,
        ${right + 1}px ${top}px,
        ${left}px ${top}px,
        ${left}px ${bottom}px,
        ${right}px ${bottom}px,
        ${right}px ${top}px,
        100% 0%,
        100% 100%,
        0% 100%
      )`

      return {
        '-webkit-clip-path': value,
        'clip-path': value,
      }
    },

    getCardPosition() {
      return {
        top: this.card.top + 'px',
        left: this.card.left + 'px',
      }
    },

    getAnimationStyles() {
      if(this.currentMarkIndex <= 0)
        return { transition: 'none' }
      return {}
    },

    getCoachMarks() {
      return this.coachMarks.filter((mark) => !this.IS_MARK_WATCHED(mark.name))
    },

    getCoach() {
      return this.getCoachMarks?.[this.currentMarkIndex]
    },

    canShow() {
      return !this.isScrolling && this.showMarks
    },
  },

  watch: {
    getCoach: {
      immediate: true,
      handler(hasCoach) {
        document.body.style.overflow = hasCoach ? 'hidden' : ''
      },
    },
  },

  methods: {
    ...mapMutations([
      'SAVE_WATCHED_MARKS',
    ]),

    initMarks() {
      this.coachMarks = JSON.parse(JSON.stringify(this.marks))
      this.coachMarks = this.coachMarks.filter((mark) => this.getHTMLElement(this.htmlDataKeys.mark, mark.name))
      this.currentMarkIndex = 0
    },

    setCurrentViewPosition() {
      if (!this.getCoach) return null

      const HTMLElement = this.getHTMLElement(this.htmlDataKeys.mark, this.getCoach.name)
      if (!HTMLElement) return null
      const rect = HTMLElement.getBoundingClientRect()

      this.viewport.width = rect.width
      this.viewport.height = rect.height
      this.viewport.top = rect.top
      this.viewport.left = rect.left
      this.viewport.right = rect.right
      this.viewport.bottom = rect.bottom
    },

    setCurrentCardPosition() {
      const position = this.getCardPossiblePlaces()

      if (!position) {
        return
      }

      this.card.type = position.type
      this.card.top = position.top
      this.card.left = position.left
    },

    getCardPossiblePlaces() {
      const card = this.$refs.card
      if (!card) return null

      const rect = card.getBoundingClientRect()
      const pointOffset = 7
      const arrowDistance = 50 + 7 + 2 // 50 - distance of arrow, 7 - half of width, 2 - card's border width
      const toOffset = arrowDistance * 2
      const moveHorizontalPXIfNeeded = this.viewport.width < 200 ? this.viewport.width / 2 : 0

      // определяем все возможные позиции
      let positions = [
        {
          type: 'bottom-left',
          top: this.viewport.bottom + pointOffset,
          left: this.viewport.left - rect.width + (toOffset) + moveHorizontalPXIfNeeded,
        },
        {
          type: 'bottom-left',
          top: this.viewport.bottom + pointOffset,
          left: this.viewport.left - rect.width + toOffset,
        },
        {
          type: 'bottom-right',
          top: this.viewport.bottom + pointOffset,
          left: this.viewport.right - toOffset,
        },
        {
          type: 'bottom-center',
          top: this.viewport.bottom + pointOffset,
          left: (this.viewport.left + this.viewport.width / 2) - rect.width / 2,
        },

        {
          type: 'top-left',
          top: this.viewport.top - rect.height - pointOffset,
          left: this.viewport.left - rect.width + toOffset,
        },
        {
          type: 'top-center',
          top: this.viewport.top - rect.height - pointOffset,
          left: (this.viewport.left + this.viewport.width / 2) - rect.width / 2,
        },
        {
          type: 'top-right',
          top: this.viewport.top - rect.height - pointOffset,
          left: this.viewport.right - toOffset,
        },

        {
          type: 'left-top',
          top: this.viewport.top - rect.height + toOffset,
          left: this.viewport.left - rect.width - pointOffset,
        },
        {
          type: 'left-center',
          top: (this.viewport.top + this.viewport.height / 2) - rect.height / 2,
          left: this.viewport.left - rect.width - pointOffset,
        },
        {
          type: 'left-bottom',
          top: this.viewport.bottom - toOffset,
          left: this.viewport.left - rect.width - pointOffset,
        },

        {
          type: 'right-top',
          top: this.viewport.top - rect.height + toOffset,
          left: this.viewport.right + pointOffset,
        },
        {
          type: 'right-center',
          top: (this.viewport.top + this.viewport.height / 2) - rect.height / 2,
          left: this.viewport.right + pointOffset,
        },
        {
          type: 'right-bottom',
          top: this.viewport.bottom - toOffset,
          left: this.viewport.right + pointOffset,
        },
      ]

      // фильтруем позиции: если карточка как-то заходит за предели видимости, убираем такую позицию
      positions = positions.filter((position) => {
        if (position.left < 0 || position.top < 0) {
          return false
        }

        if (position.top + rect.height > window.innerHeight) {
          return false
        }

        if (position.left + rect.width > window.innerWidth) {
          return false
        }

        return true
      })

      const expectingPosition = positions.find((position) => position.type === this.getCoach.position)

      // можно было бы сделать выборку из уже существующих по желательным позициям...
      return expectingPosition || positions[0]
    },

    onClickSkip() {
      this.saveWatch()
    },

    async onClickNext() {
      this.next()
    },

    isVisible(element, prop = 'display'){
      if (element && element.nodeType === 1) {
        let styles = getComputedStyle(element)
        if (prop === 'opacity' && styles.opacity == 0 || styles.visibility == 'hidden')
          return false
        if (prop === 'display' && styles.display == 'none')
          return false
        return this.isVisible(element.parentElement, prop)
      }

      return true
    },

    async draw() {
      if (this.isScrolling) return null
      if (!this.getCoach) return null

      this.showMarks = false

      try {
        await this.scrollIntoViewIfNeeded()
        await this.delay(this.getCoach.delay ?? 0)
      } catch (e) {
        console.log(e)
      }

      this.showMarks = true

      this.setCurrentViewPosition()
      await this.$nextTick()
      this.setCurrentCardPosition()
    },

    scrollIntoViewIfNeeded() {
        const cookieAlertHeight = this.getCookieAlertHeight()

        return new Promise((resolve, reject) => {
            (async () => {
                const HTMLElement = this.getHTMLElement(this.htmlDataKeys.mark, this.getCoach.name)
                if (!HTMLElement || this.isInViewport(HTMLElement, cookieAlertHeight)) return reject()
                //TODO: для скролла ниже добавить проверку GET_IS_COOKIE_ALERT_SHOW && GET_IS_MOBILE


                this.isScrolling = true
                let offset = window.innerHeight * -1 + HTMLElement.clientHeight + cookieAlertHeight

                const scrollSpeed = 300

                this.$smoothScroll({
                    offset,
                    scrollTo: HTMLElement,
                    duration: scrollSpeed,
                })

                await this.delay(scrollSpeed)

                if (!this.isInViewport(HTMLElement, cookieAlertHeight)) {
                    this.$smoothScroll({
                        offset,
                        scrollTo: HTMLElement,
                        duration: scrollSpeed,
                    })

                    await this.delay(scrollSpeed)
                }

                this.isScrolling = false

                resolve()
            })()
      })
    },

    getHTMLElement(key, name) {
      return Array.from(
        document.querySelectorAll(`[${key}="${ name }"]`)
      ).find(element => this.isVisible(element))
    },

    isInViewport(el, additionalHeight = 0){
      let rect = el.getBoundingClientRect()

      return (
        rect.bottom - rect.height / 2 >= 0 &&
        rect.top + rect.height + additionalHeight / 2 <= window.innerHeight
      )
    },

    delay(ms) {
      return new Promise((resolve) => setTimeout(resolve, ms))
    },

    async next() {
      this.currentMarkIndex += 1
      if (this.getCoach) {
        this.draw()
      } else {
        this.stop()
        this.saveWatch()
      }
    },

    saveWatch() {
      this.SAVE_WATCHED_MARKS(this.coachMarks.map(el => el.name))
    },

    stop() {
      window.addEventListener('resize', this.draw)
    },
    getCookieAlertHeight() {
      if (!this.GET_IS_COOKIE_ALERT_SHOW) return 0

      const alert = this.getHTMLElement(this.htmlDataKeys.name, 'cookie-alert')

      if (!alert) return 0;

      const { height, bottom } = alert.getBoundingClientRect()

      return window.innerHeight - bottom + height
    },
  },
}
</script>

<style lang="scss">

.vue-coach-marks {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999;
}
.vue-coach-marks__overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;

  background-color: rgba(0,0,0,.5);

  transition: 200ms;
}

.vue-coach-marks__card {
  position: absolute;
  z-index: 1;
  width: 290px;

  background-color: #fff;
  border: 2px solid #000;

  $toArrow: 50px;

  //transition: 500ms;

  &:after {
    content: "";
    position: absolute;
    border: 7px solid transparent;
    filter: drop-shadow(0px 0px 3px #fff);
  }

  &--bottom-left:after,
  &--bottom-right:after,
  &--bottom-center:after {
    bottom: 100%;
    border-bottom: 14px solid;
  }


  &--top-left:after,
  &--top-right:after,
  &--top-center:after {
    top: 100%;
    border-top: 14px solid;
  }
  &--top-right:after,
  &--bottom-right:after {
    left: $toArrow;
  }
  &--top-left:after,
  &--bottom-left:after {
    right: $toArrow;
  }
  &--top-center:after,
  &--bottom-center:after {
    left: 50%;
    transform: translateX(-50%);
  }

  &--left-top:after,
  &--left-center:after,
  &--left-bottom:after {
    left: 100%;
    border-left: 14px solid;
  }
  &--right-top:after,
  &--right-center:after,
  &--right-bottom:after {
    right: 100%;
    border-right: 14px solid;
  }
  &--right-top:after,
  &--left-top:after {
    bottom: $toArrow;
  }
  &--right-center:after,
  &--left-center:after {
    top: 50%;
    transform: translateY(-50%);
  }
  &--right-bottom:after,
  &--left-bottom:after {
    top: $toArrow;
  }
}
.vue-coach-marks__card-content {
  padding: 20px;
}
.vue-coach-marks__card-title {
  text-transform: uppercase;
  font: 800 21px Raleway;
}
.vue-coach-marks__card-text {
  margin-top: 10px;
}
.vue-coach-marks__card-buttons {
  display: flex;
}
.vue-coach-marks__card-buttons-item {
  flex-grow: 1;
  min-width: auto;
}

</style>
