export const CHARACTERS = {
    Fang: {
        level: '',
        tier: 3,
        type: 'fang',
        name: 'Fang',
        attributes: [
            { number: 10, percent: 80, title: "atk"},
            { number: 10, percent: 80, title: "mod"},
            { number: 10, percent: 80, title: "spd"},
            { number: 10, percent: 80, title: "stm"},
        ],
    },
    Hiro: {
        level: '',
        tier: 4,
        type: 'hiro',
        name: 'Hiro',
        attributes: [
            { number: 10, percent: 80, title: "atk"},
            { number: 10, percent: 80, title: "mod"},
            { number: 10, percent: 80, title: "spd"},
            { number: 10, percent: 80, title: "stm"},
        ],
    },
    Mejai: {
        level: '',
        tier: 1,
        type: 'mejai',
        name: 'Mejai',
        attributes: [
            { number: 10, percent: 80, title: "atk"},
            { number: 10, percent: 80, title: "mod"},
            { number: 10, percent: 80, title: "spd"},
            { number: 10, percent: 80, title: "stm"},
        ],
    },
    Nash: {
        level: '',
        tier: 5,
        type: 'nash',
        name: 'Nash',
        attributes: [
            { number: 10, percent: 80, title: "atk"},
            { number: 10, percent: 80, title: "mod"},
            { number: 10, percent: 80, title: "spd"},
            { number: 10, percent: 80, title: "stm"},
        ],
    },
    Pogo: {
        level: '',
        tier: 1,
        type: 'pogo',
        name: 'Pogo',
        attributes: [
            { number: 10, percent: 80, title: "atk"},
            { number: 10, percent: 80, title: "mod"},
            { number: 10, percent: 80, title: "spd"},
            { number: 10, percent: 80, title: "stm"},
        ],
    },
    ShenLi: {
        level: '',
        tier: 5,
        type: 'shen-li',
        name: 'ShenLi',
        attributes: [
            { number: 10, percent: 80, title: "atk"},
            { number: 10, percent: 80, title: "mod"},
            { number: 10, percent: 80, title: "spd"},
            { number: 10, percent: 80, title: "stm"},
        ],
    },
    Kira: {
        level: '',
        tier: 5,
        type: 'shen-li',
        name: 'ShenLi',
        attributes: [
            { number: 10, percent: 80, title: "atk"},
            { number: 10, percent: 80, title: "mod"},
            { number: 10, percent: 80, title: "spd"},
            { number: 10, percent: 80, title: "stm"},
        ],
    },
    Shenzu: {
        level: '',
        tier: 4,
        type: 'shenzu',
        name: 'Shenzu',
        attributes: [
            { number: 10, percent: 80, title: "atk"},
            { number: 10, percent: 80, title: "mod"},
            { number: 10, percent: 80, title: "spd"},
            { number: 10, percent: 80, title: "stm"},
        ],
    },
    Thorodin: {
        level: '',
        tier: 3,
        type: 'thorodin',
        name: 'Thorodin',
        attributes: [
            { number: 10, percent: 80, title: "atk"},
            { number: 10, percent: 80, title: "mod"},
            { number: 10, percent: 80, title: "spd"},
            { number: 10, percent: 80, title: "stm"},
        ],
    },
    Tigra: {
        level: '',
        tier: 2,
        type: 'tigra',
        name: 'Tigra',
        attributes: [
            { number: 10, percent: 80, title: "atk"},
            { number: 10, percent: 80, title: "mod"},
            { number: 10, percent: 80, title: "spd"},
            { number: 10, percent: 80, title: "stm"},
        ],
    },
    Volkov: {
        level: '',
        tier: 1,
        type: 'volkov',
        name: 'Volkov',
        attributes: [
            { number: 10, percent: 80, title: "atk"},
            { number: 10, percent: 80, title: "mod"},
            { number: 10, percent: 80, title: "spd"},
            { number: 10, percent: 80, title: "stm"},
        ],
    },
    Zod: {
        level: '',
        tier: 5,
        type: 'zod',
        name: 'Zod',
        attributes: [
            { number: 10, percent: 80, title: "atk"},
            { number: 10, percent: 80, title: "mod"},
            { number: 10, percent: 80, title: "spd"},
            { number: 10, percent: 80, title: "stm"},
        ],
    },
}
