<template>
  <div class="banner__bg-video-wrap">
    <video autoplay loop muted class="banner__bg-video" v-if="GET_IS_MOBILE">
      <source src="@/assets/video/banner-320x290.mp4" type="video/mp4">
    </video>
    <video autoplay loop muted class="banner__bg-video" v-else-if="GET_IS_TABLET">
      <source src="@/assets/video/banner-640x370.mp4" type="video/mp4">
    </video>
    <video autoplay loop muted class="banner__bg-video" v-else>
      <source src="@/assets/video/banner-1920x890.mp4" type="video/mp4"/>
    </video>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters([
      'GET_IS_MOBILE',
      'GET_IS_TABLET',
    ])
  }
}
</script>

<style lang="scss">
.banner__bg-video {
  content: "";
  position: absolute;
  z-index: -1;
  top: 0px;
  left: 0;
  display: block;
  width: 100%;
  height: 100%;
  min-height: 290px;
  object-fit: cover;
}

@media screen and (max-width: ($bp_desktop - 1px)) {
  .banner__bg-video-wrap {
    position: relative;

    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(0deg, #04091B 3.85%, rgba(4, 9, 27, 0.44) 13.26%, rgba(4, 9, 27, 0.00) 27.59%);
    }
  }

  .banner__bg-video {
    position: relative;
    z-index: 0;
    min-height: 370px;
  }
}

@media screen and (max-width: ($bp_mb - 1px)) {
  .banner__bg-video {
    height: 100%;
    object-fit: cover;
  }
}
</style>
