var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "partners" },
    [
      _c("y-line", { attrs: { position: "6" } }),
      _c("div", { staticClass: "container" }, [
        _c(
          "h2",
          {
            staticClass: "section-header section-header--adaptive",
            attrs: { "data-aos": "fade-up", "data-aos-delay": "50" },
          },
          [_vm._v(" Partners ")]
        ),
        _c(
          "div",
          {
            staticClass: "partners-slider",
            attrs: {
              "data-aos": "fade-up",
              "data-aos-delay": "150",
              "data-aos-duration": "850",
              "data-aos-offset": "200",
            },
          },
          [
            _c(
              "vue-slick-carousel",
              _vm._b({}, "vue-slick-carousel", _vm.getSliderSettings, false),
              _vm._l(_vm.partners, function (partner, i) {
                return _c("div", { key: i }, [
                  _c("div", { staticClass: "partners-slider__item" }, [
                    _c("img", {
                      staticClass: "partners-slider__img",
                      attrs: {
                        loading: "lazy",
                        src: require(`@/assets/img/partners/new/${partner.img}`),
                        alt: "parner slider logotype",
                      },
                    }),
                  ]),
                ])
              }),
              0
            ),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }