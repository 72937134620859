var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      {
        staticClass: "banner__events",
        attrs: { "data-aos": "fade-left", "data-aos-delay": "200" },
      },
      _vm._l(_vm.banners, function (banner) {
        return _c(
          "a",
          {
            key: banner.id,
            staticClass: "banner__event",
            attrs: { href: banner.url },
          },
          [
            _c("img", {
              staticClass: "banner__event-image",
              attrs: {
                src:
                  _vm.banners.length > 1
                    ? banner.banner
                    : banner.wide_banner || banner.banner,
                alt: "event",
              },
            }),
          ]
        )
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }