var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "tiny-stat", class: { "tiny-stat--small": _vm.isSmall } },
    [
      _c("div", { staticClass: "tiny-stat__text" }, [
        _vm._v(" " + _vm._s(_vm.title) + " "),
      ]),
      _c("ProgressBarBlocks", {
        staticClass: "tiny-stat__progress",
        attrs: { count: _vm.number },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }