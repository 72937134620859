// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".banner__events {\n  height: 106px;\n  max-width: 463px;\n  margin: 0 auto 20px;\n  display: flex;\n  gap: 20px;\n}\n@media screen and (max-width: 1820px) {\n.banner__events {\n    max-width: 410px;\n}\n}\n@media screen and (max-width: 1679px) {\n.banner__events {\n    max-width: 390px;\n}\n}\n@media screen and (max-width: 1535px) {\n.banner__events {\n    max-width: 406px;\n}\n}\n@media screen and (max-width: 1439px) {\n.banner__events {\n    max-width: 346px;\n}\n}\n@media screen and (max-width: 1365px) {\n.banner__events {\n    max-width: 330px;\n}\n}\n@media screen and (max-width: 1279px) {\n.banner__events {\n    max-width: 350px;\n}\n}\n@media screen and (max-width: 1023px) {\n.banner__events {\n    max-width: 410px;\n}\n}\n@media screen and (max-width: 639px) {\n.banner__events {\n    max-width: 250px;\n}\n}\n.banner__event {\n  flex: 1;\n  display: block;\n  height: 100%;\n  border: 3px solid white;\n}\n.banner__event-image {\n  -o-object-fit: cover;\n     object-fit: cover;\n  height: 100%;\n  width: 100%;\n}", ""]);
// Exports
exports.locals = {
	"bp_mb_xs": "430px",
	"bp-mb": "640px",
	"bp-laptop": "1630px",
	"bp-desktop": "992px"
};
module.exports = exports;
