var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "button",
    {
      staticClass: "icon-btn",
      style: {
        width: `${_vm.size}px`,
        height: `${_vm.size}px`,
      },
      on: {
        click: function ($event) {
          return _vm.$emit("click")
        },
      },
    },
    [
      _c("img", {
        staticClass: "icon-btn__icon icon-btn__icon--play",
        attrs: {
          src: require("@/assets/img/icons/play.png"),
          alt: "icon btn play",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }