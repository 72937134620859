<template>
  <div class="youtube-video">
    <i class="youtube-video__layout" />

    <icon-btn
      class="youtube-video__btn"
      @click="onPlay"
      :size="btnSize"
    />

    <div
      class="youtube-video r16x9"
      :style="{
        backgroundImage: `url(${getPreview})`,
      }"
    >
      <iframe
        v-if="getCanPlay"
        width="560"
        height="315"
        :src="getVideoURL"
        frameborder="0"
        allowfullscreen
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
      />
    </div>
  </div>
</template>

<script>
import iconBtn from './icon-btn.vue'
export default {
  name: "YoutubeVideo",
  components: {
    iconBtn,
  },
  props: {
    src: { type: String, required: true },
    btnSize: { type: [Number, String], default: 77 },
  },
  data() {
    return {
      canPlay: false,
    }
  },
  computed: {
    getCanPlay() {
      return this.canPlay && this.getVideoURL
    },

    getVideoURL() {
      return `https://www.youtube.com/embed/${this.getVideoHash}?autoplay=1`
    },

    getPreview() {
      return `https://img.youtube.com/vi/${this.getVideoHash}/sddefault.jpg`
    },

    getVideoHash() {
      return this.src.match(/[0-9a-z-_]{11}/i)[0]
    },
  },
  methods: {
    onPlay() {
      this.canPlay = true
    },
  },
}
</script>

<style lang="scss">

.youtube-video__layout {
  position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
  z-index: 1;

  background-color: rgba(0,0,0, 0.2);
}

.youtube-video__btn {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
  margin: auto;
}

/* adaptive video */
.youtube-video {
  background: center center no-repeat;
  background-size: cover;
  position: relative;
}

.youtube-video.r16x9 {
  padding-bottom: 56.25%; /* 16:9 */
}
.youtube-video.r4x3 {
  padding-bottom: 75%; /* 4:3 */
}
.youtube-video.r3x2 {
  padding-bottom: 66.66%; /* 3:2 */
}
.youtube-video.r8x5 {
  padding-bottom: 62.5%; /* 8:5 */
}
.youtube-video iframe {
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.youtube-video iframe[src=""] {
  display: none;
}

</style>
