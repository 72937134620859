<template>
  <div
    v-if="!GET_IS_MOBILE"
    :class="['line', {
      [`line--pos-${position}`]: position,
    }]"
  >
    <div
      v-if="animate"
      class="line__movable-line"
      :style="{
        animationDelay: `${delay}ms`
      }"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: "YLine",
  props: {
    position: { type: String, default: '' },
    delay: { type: [Number, String], default: 0 },
    animate: { type: Boolean, default: true },
  },
  computed: {
    ...mapGetters([
      'GET_IS_MOBILE',
    ]),
  },
}
</script>

<style lang="scss" scoped>

.line {
  position: absolute;
  top: 0;
  left: calc(50% - 815px);
  bottom: 0;
  overflow: hidden;

  width: 2px;
  z-index: 0;
  background-color: #DDDCDB;

  &--pos-1 {
    margin-left: 5px;
  }
  &--pos-2 {
    margin-left: 40px;
  }
  &--pos-3 {
    margin-left: 390px;
  }
  &--pos-4 {
    margin-left: 480px;
  }
  &--pos-5 {
    margin-left: 665px;
  }

  /* from right */
  &--pos-6,
  &--pos-7,
  &--pos-8 {
    left: calc(50% + 815px);
  }

  &--pos-6 {
    margin-left: -434px;
  }
  &--pos-7 {
    margin-left: -369px;
  }
  &--pos-8 {
    margin-left: -140px;
  }
}

.line__movable-line {
  position: absolute;
  top: calc(0% - 200px);
  left: 0;
  right: 0;
  height: 100px;
  
  background-color: #FF9509;

  animation: 5s MOVE-LINES infinite linear;
  
  @keyframes MOVE-LINES {
    0% {
      top: calc(0% - 200px);
    }
    40% {
      transform: scaleY(2);
    }
    70% {
      transform: scaleY(0.75);
    }
    100% {
      transform: scaleY(1);
      top: 100%;
    }
  }
}

</style>
