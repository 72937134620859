<template>
  <!-- TODO: доработать состояние -->
  <button
    class="icon-btn"
    @click="$emit('click')"
    :style="{
      width: `${size}px`,
      height: `${size}px`,
    }"
  >
    <img
      src="@/assets/img/icons/play.png"
      alt="icon btn play"
      class="icon-btn__icon icon-btn__icon--play"
    >
  </button>
</template>

<script>
export default {
  name: "IconBtn",
  props: {
    size: { type: [Number, String], default: 77 },
  },
}
</script>

<style lang="scss">


.icon-btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;

  border-radius: 50%;

  border: 2px solid transparent;
  transition: background-color 300ms;

  &:after {
    content: "";
    position: absolute;
    top: -2px;
    left: -2px;
    right: -2px;
    bottom: -2px;
    z-index: -1;
    border-radius: inherit;
    background: linear-gradient(to right, #FF9509, #FFCC13);

    opacity: 1;
    transition: opacity 300ms;
  }

  &:hover {
    background-color: #fff;
    &:after {
      opacity: 0;
    }
  }
}

.icon-btn__icon {
  max-width: 45%;
}

.icon-btn__icon--play {
  transform: translateX(18%);
}

</style>
