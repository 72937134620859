// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".fighter-info-card {\n  position: relative;\n  width: 100%;\n  max-width: 385px;\n  min-height: 160px;\n  padding-top: 35px;\n}\n.fighter-info-card:before, .fighter-info-card:after {\n  content: \"\";\n  position: absolute;\n  top: 0;\n  left: 0;\n  right: 0;\n  bottom: 0;\n  z-index: -1;\n  transition: opacity 300ms;\n}\n.fighter-info-card:before {\n  opacity: 0;\n  background: linear-gradient(-22deg, #FF9509, #FFCC13);\n}\n.fighter-info-card:after {\n  border: 2px solid #D0D0D1;\n  background-color: #fff;\n}\n.fighter-info-card.active {\n  border-color: transparent;\n}\n.fighter-info-card.active:before {\n  opacity: 1;\n}\n.fighter-info-card.active:after {\n  opacity: 0;\n}\n.fighter-info-card__header {\n  position: absolute;\n  top: 0;\n  left: 50%;\n  margin: auto;\n  transform: translate(-50%, -50%);\n}\n.fighter-info-card__header .button__content {\n  text-align: center;\n}\n.fighter-info-card__content {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  padding: 35px;\n  font: 500 18px \"Tektur\";\n  text-align: center;\n  min-height: 100%;\n}\n@media screen and (min-width: 641px) and (max-width: 1200px) {\n.fighter-info-card__content {\n    padding: 15px;\n}\n}\n@media screen and (max-width: 640px) {\n.fighter-info-card {\n    max-width: 100%;\n}\n.fighter-info-card:after {\n    border-width: 4px;\n}\n}", ""]);
// Exports
exports.locals = {
	"bp_mb_xs": "430px",
	"bp-mb": "640px",
	"bp-laptop": "1630px",
	"bp-desktop": "992px"
};
module.exports = exports;
