<template>
  <div class="fighters">
    <y-line position="1" />
    <y-line position="6" />

    <div class="container">
      <h2 class="section-header section-header--adaptive" data-aos="fade-up" data-aos-delay="0">
        A FIGHTING GAME<br/>
        <span class="section-header--fz-58 section-header--gradient">METAVERSE</span>
      </h2>
      <div class="fighters__desc" data-aos="fade-up" data-aos-delay="100">
        MetaFighter is a skill-based Play-to-Earn NFT fighting game which captures the essence of arcade fighting classics
      </div>

      <div class="fighters__cards">
        <div
          class="fighters__cards-item fighters__cards-item--1"
          data-aos="fade-right"
          data-aos-delay=""
          :data-aos-offset="700 + 50 - 300"
          :data-aos-once="true"
        >
          <fighter-info-card
            class="fighters__card"
            title="FIGHTER NFT"
            text="Your own fighter upgrade and craft your unique fighter while you master the game."
          />
          <div class="fighters__cards-line fighters__cards-line--1" data-aos="fade-down-right" data-aos-delay="50">
            <i></i>
          </div>
        </div>

        <div
          class="fighters__cards-item fighters__cards-item--5"
          data-aos="fade-right"
          data-aos-delay=""
          :data-aos-offset="700 + 50 - 300"
          :data-aos-once="true"
        >
          <fighter-info-card
            class="fighters__card"
            title="ARCADE GAMING"
            text="No Crypto? No Problem! Just play and have fun, no need for any Blockchain assets."
          />
          <div class="fighters__cards-line fighters__cards-line--5" data-aos="fade-down-right" data-aos-delay="50">
            <i></i>
          </div>
        </div>

        <div
          class="fighters__cards-item fighters__cards-item--2"
          data-aos="fade-left"
          data-aos-delay="100"
          :data-aos-offset="700 + 50 - 300"
          :data-aos-once="true"
        >
          <fighter-info-card
            class="fighters__card"
            title="FIGHT TO EARN"
            text="Get rewards for playing. Win $MF tokens for dominating competition  in tournaments and wager fights."
          />
          <div data-aos="fade-down-left" data-aos-delay="150">
            <div class="fighters__cards-line fighters__cards-line--2">
              <i></i>
            </div>
          </div>
        </div>

        <div
          class="fighters__cards-item fighters__cards-item--3"
          data-aos="fade-right"
          data-aos-delay="200"
          :data-aos-offset="400 + 50 - 300"
          :data-aos-once="true"
        >
          <fighter-info-card
            class="fighters__card"
            title="ARENA NFT"
            text="Your own Business. Scale your business and earn passive income."
          />
          <div class="fighters__cards-line fighters__cards-line--3" data-aos="fade-down-right" data-aos-delay="250">
            <i></i>
          </div>
        </div>

        <div
          class="fighters__cards-item fighters__cards-item--4"
          data-aos="fade-left"
          data-aos-delay="300"
          :data-aos-offset="400 + 50 - 300"
          :data-aos-once="true"
        >
          <fighter-info-card
            class="fighters__card"
            title="MARKETPLACE"
            text="Trade. Rent. Earn. Generate revenues with your assets as you progress."
          />
          <div class="fighters__cards-line fighters__cards-line--4" data-aos="fade-down-left" data-aos-delay="350">
            <i></i>
          </div>
        </div>
      </div>

      <div
        class="fighters__persons"
        data-aos="fade-up"
        :data-aos-offset="400 - 300"
      >
        <div
          v-if="!GET_IS_MOBILE"
          class="fighters__persons-item fighters__persons-item--1"
        >
        <picture>
          <source srcset="@/assets/img/fighters/meta_1.webp" type="image/webp">
          <source srcset="@/assets/img/fighters/meta_1.png" type="image/png">
          <img loading="lazy" src="@/assets/img/fighters/meta_1.png" alt="warior">
        </picture>
        </div>
        <div
          class="fighters__persons-item fighters__persons-item--2"
        >
        <picture>
          <source srcset="@/assets/img/fighters/meta_2.webp" type="image/webp">
          <source srcset="@/assets/img/fighters/meta_2.png" type="image/png">
          <img loading="lazy" src="@/assets/img/fighters/meta_2.png" alt="warior">
        </picture>
        </div>
        <div
          class="fighters__persons-item fighters__persons-item--3"
        >
        <picture>
          <source srcset="@/assets/img/fighters/meta_3.webp" type="image/webp">
          <source srcset="@/assets/img/fighters/meta_3.png" type="image/png">
          <img loading="lazy" src="@/assets/img/fighters/meta_3.png" alt="warior">
        </picture>
        </div>
        <div
          class="fighters__persons-item fighters__persons-item--4"
        >
        <picture>
          <source srcset="@/assets/img/fighters/meta_4.webp" type="image/webp">
          <source srcset="@/assets/img/fighters/meta_4.png" type="image/png">
          <img loading="lazy" src="@/assets/img/fighters/meta_4.png" alt="warior">
        </picture>
        </div>
        <div
          v-if="!GET_IS_MOBILE"
          class="fighters__persons-item fighters__persons-item--5"
        >
        <picture>
          <source srcset="@/assets/img/fighters/meta_5.webp" type="image/webp">
          <source srcset="@/assets/img/fighters/meta_5.png" type="image/png">
          <img loading="lazy" src="@/assets/img/fighters/meta_5.png" alt="warior">
        </picture>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FighterInfoCard from '../common/fighter-info-card.vue'
import YLine from '@/components/common/y-line.vue'
import { mapGetters } from 'vuex'

export default {
  name: "Fighters",
  components: {
    FighterInfoCard,
    YLine,
  },
    computed: {
    ...mapGetters([
      'GET_IS_MOBILE',
    ]),
  }
}
</script>

<style lang="scss">

.fighters {
  position: relative;
  padding: 90px 65px 68px;
}

.fighters__desc {
  margin: 15px auto 0;
  max-width: 520px;

  font-size: 18px;
  font-weight: 500;
  text-align: center;
  text-transform: uppercase;
}

.fighters__cards {
  position: relative;
  z-index: 3;
  max-width: 1440px;
  margin: 50px auto 0;

  display: grid;
  grid-template-columns: 385px 385px 385px;
  grid-template-rows: 1fr 1fr;
  justify-content: space-between;
  grid-gap: 147px 30px;
}

.fighters__cards-item--1 {
  grid-area: 1 / 1 / 2 / 2;
}
.fighters__cards-item--5 {
  grid-area: 1 / 2 / 2 / 3;
}
.fighters__cards-item--2 {
  grid-area: 1 / 3 / 2 / 4;
}
.fighters__cards-item--3 {
  grid-area: 2 / 1 / 3 / 2;
}
.fighters__cards-item--4 {
  grid-area: 2 / 3 / 3 / 4;
}

.fighters__card {
  height: 100%;
}

.fighters__cards-item {
  position: relative;
}

.fighters__cards-line {
  position: absolute;
  color: #D0D0D1;

  transition: 300ms;
}

.active ~ .fighters__cards-line,
.active ~ * .fighters__cards-line {
  color: #FFA30B;
}

.fighters__cards-line--1,
.fighters__cards-line--2 {
  margin-top: 5px;
  top: 100%;
}
.fighters__cards-line--1 {
  left: 197px;
}

.fighters__cards-line--1,
.fighters__cards-line--2 {
  width: 364px;
  height: 185px;

  &:before,
  &:after {
    content: "";
    position: absolute;
    right: 2px;

    border: 2px solid;
    border-top: none;
    border-right: none;
  }

  &:before {
    top: 2px;
    left: 2px;
    height: 57px;
  }

  &:after {
    top: 59px;
    bottom: 2px;

    border-bottom: none;
  }

  & > * {
    &:before,
    &:after {
      content: "";
      position: absolute;
      border: 3px solid;
      border-radius: 50%;
    }

    &:before {
      left: 0;
      top: 0;
    }
    &:after {
      right: 0;
      bottom: 0;
    }
  }
}

.fighters__cards-line--2 {
  width: 415px;
  right: 197px;
  transform: scaleX(-1);
}

.fighters__cards-line--3,
.fighters__cards-line--4 {
  top: 50%;
  width: 120px;
  height: 6px;

  &:after {
    content: "";
    position: absolute;
    top: 2px;
    left: 2px;
    right: 2px;

    border-top: 2px solid;
  }

  & > * {
    &:before,
    &:after {
      content: "";
      position: absolute;
      border: 3px solid;
      border-radius: 50%;
      top: 0;
    }

    &:before {
      left: 0;
    }
    &:after {
      right: 0;
    }
  }
}

.fighters__cards-line--3 {
  left: 100%;
  margin-left: 5px;
}
.fighters__cards-line--4 {
  width: 180px;
  right: 100%;
  margin-right: 5px;
}

.fighters__cards-line--5 {
  top: 100%;
  left: 50%;
  margin-top: 5px;
  width: 6px;
  height: 70px;

  &:after {
    content: "";
    position: absolute;
    top: 2px;
    left: 2px;
    bottom: 2px;

    border-left: 2px solid;
  }

  & > * {
    &:before,
    &:after {
      content: "";
      position: absolute;
      border: 3px solid;
      border-radius: 50%;
      left: 0;
    }

    &:before {
      top: 0;
    }
    &:after {
      bottom: 0;
    }
  }
}

.fighters__persons {
  position: relative;
  z-index: 2;

  margin-top: -240px;
  padding-left: 66px;

  display: flex;
  justify-content: center;
  align-items: flex-end;
}
.fighters__persons-item img {
  width: 100%;
}
.fighters__persons-item--1 {
  margin-bottom: 193px;
}
.fighters__persons-item--2 {
  margin: 0 0 108px -87px;
}
.fighters__persons-item--3 {
  margin: 0 -50px 0 -144px;
}
.fighters__persons-item--4 {
  margin-bottom: 108px;
}
.fighters__persons-item--5 {
  position: relative;
  z-index: -1;
  margin: 0 0 250px -68px;
}

@media screen and (min-width: $bp_mb + 1px) and (max-width: 1600px) {
  .fighters {
    padding-right: 0;
    padding-left: 0;
  }

  .fighters__cards {
    max-width: 100%;
    grid-template-columns: 333px 333px 333px;
  }

  .fighters__cards-line--1, .fighters__cards-line--2 {
    width: 20vw;
    height: 125px;
  }

  .fighters__cards-line--3, .fighters__cards-line--4 {
    width: 8vw;
  }

  .fighters__persons {
    padding-left: 10vw;
    font-size: 0;
  }
  .fighters__persons-item--3 {
    width: 30vw;
  }
}

@media screen and (min-width: $bp_mb + 1px) and (max-width: 1323px) {
  .fighters__persons {
    margin-top: -150px;
  }

  .fighters__cards-line--5 {
    height: 125px;
  }
}

@media screen and (min-width: $bp_mb + 1px) and (max-width: 1170px) {
  .fighters__cards {
    margin-top: 50px;
    grid-template-columns: 1fr 1fr 1fr;
  }

  .fighters__persons {
    margin-top: -100px;
  }

  .fighters__persons-item--1,
  .fighters__persons-item--5 {
    margin-bottom: 100px;
  }

  .fighters__persons-item--2,
  .fighters__persons-item--4 {
    margin-bottom: 50px;
  }
}

@media screen and (min-width: $bp_mb + 1px) and (max-width: 970px) {
  .fighters {
    padding-top: 60px;
    padding-bottom: 100px;
  }
  .fighters__cards-line {
    display: none;
  }

  .fighters__cards {
    grid-template-columns: 1fr;
    grid-gap: 30px;
  }
  .fighters__cards-item {
    grid-area: auto;
  }
  .fighter-info-card {
    max-width: 400px;
    margin: auto;
  }

  .fighters__persons {
    padding: 0;
    margin-top: 30px;
  }

  .fighters__persons-item--1 {
    width: 15vw;
  }
  .fighters__persons-item--2 {
    width: 31vw;
  }
  .fighters__persons-item--4 {
    width: 20vw;
  }
  .fighters__persons-item--5 {
    width: 25vw;
  }
}

@media screen and (max-width: $bp_mb) {
  .fighters {
    padding: 40px 0 70px;
  }

  .fighters__desc {
    max-width: 560px;
    font-size: 28px;
  }

  .fighters__cards {
    margin-top: 75px;
    display: block;
  }
  .fighters__cards-line {
    display: none;
  }

  .fighters__cards-item {
    margin-bottom: 96px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .fighters__persons {
    z-index: 5;
    margin: -30px 0 0;
    padding: 0 0 0 58px;
  }

  .fighters__persons-item {
    img {
      width: 100%;
      height: auto;
    }
    &--2 {
      width: 290px;
      margin-bottom: 44px;
    }
    &--3 {
      width: 307px;
    }
    &--4 {
      z-index: -1;
      margin-bottom: 29px;
      margin-left: -47px;
      width: 214px;
    }
  }
}

</style>
