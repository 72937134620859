var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { class: ["banner", { "banner--dense": _vm.events?.length }] },
    [
      _c("fires-canvas", { attrs: { count: 100 } }),
      _c("BgVideo"),
      _c("y-line", { staticClass: "banner__line", attrs: { position: "1" } }),
      _c("y-line", {
        staticClass: "banner__line",
        attrs: { delay: "3000", position: "4" },
      }),
      _c("div", { staticClass: "banner__wrap" }, [
        _c("div", {
          staticClass: "banner__gradient",
          attrs: {
            "data-aos": "fade-left",
            "data-aos-delay": "800",
            "data-aos-offset": "0",
          },
        }),
        _c(
          "div",
          {
            class: [
              "banner__inner",
              "container",
              { "banner__inner--dense": _vm.events?.length },
            ],
          },
          [
            _c(
              "div",
              { staticClass: "banner__info" },
              [
                _vm.events?.length
                  ? _c("Events", { attrs: { banners: _vm.events } })
                  : _vm._e(),
                _c(
                  "a",
                  {
                    staticClass: "banner__info-link",
                    attrs: { href: _vm.mainBanner?.url, target: "_blank" },
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "banner__info-link-inner",
                        attrs: {
                          "data-aos": "fade-left",
                          "data-aos-delay": "200",
                        },
                      },
                      [
                        _c("picture", [
                          _c("img", {
                            staticClass: "banner__info-banner",
                            attrs: {
                              src: _vm.mainBanner?.wide_image,
                              alt: "banner",
                              "data-aos": "fade-left",
                              "data-aos-delay": "0",
                            },
                          }),
                        ]),
                      ]
                    ),
                  ]
                ),
                _c(
                  "div",
                  {
                    staticClass:
                      "banner__info-title banner__info-title--gradient",
                    attrs: { "data-aos": "fade-left", "data-aos-delay": "300" },
                  },
                  [_vm._v(" MetaFighter ")]
                ),
                _c(
                  "div",
                  {
                    staticClass: "banner__info-subtitle",
                    attrs: { "data-aos": "fade-left", "data-aos-delay": "600" },
                  },
                  [_vm._v(" NFT Fighting Game ")]
                ),
                _c(
                  "div",
                  { staticClass: "banner__info-buttons" },
                  [
                    _c(
                      "router-link",
                      {
                        staticClass:
                          "banner__info-buttons-btn banner__info-buttons-btn--gradient",
                        attrs: {
                          to: "/game",
                          "data-aos": "fade-left",
                          "data-aos-delay": "800",
                          "data-aos-offset": "0",
                        },
                      },
                      [_vm._v(" Play Now ")]
                    ),
                    _c("TrailerButton"),
                  ],
                  1
                ),
                _c("div", { staticClass: "banner__info-store-wrap" }, [
                  _c(
                    "p",
                    {
                      staticClass: "banner__info-store-text",
                      attrs: {
                        "data-aos": "fade-left",
                        "data-aos-delay": "1100",
                        "data-aos-offset": "0",
                      },
                    },
                    [_vm._v(" play on your device ")]
                  ),
                  _c("div", { staticClass: "banner__info-store-btns" }, [
                    _c(
                      "a",
                      {
                        staticClass: "banner__info-store-btn",
                        attrs: {
                          href: "https://play.google.com/store/apps/details?id=com.metafighter.game",
                          target: "_blank",
                          "data-aos": "fade-left",
                          "data-aos-delay": "1300",
                          "data-aos-offset": "0",
                        },
                      },
                      [_c("BaseIcon", { attrs: { name: "play-market" } })],
                      1
                    ),
                    _c(
                      "a",
                      {
                        staticClass: "banner__info-store-btn",
                        attrs: {
                          href: "https://apps.apple.com/us/app/metafighter/id6448633678",
                          target: "_blank",
                          "data-aos": "fade-left",
                          "data-aos-delay": "1500",
                          "data-aos-offset": "0",
                        },
                      },
                      [_c("BaseIcon", { attrs: { name: "app-store" } })],
                      1
                    ),
                  ]),
                ]),
              ],
              1
            ),
          ]
        ),
      ]),
      _c("div", { staticClass: "banner__technologies" }, [
        _c(
          "div",
          { staticClass: "banner__technologies-item" },
          [
            _c("BaseIcon", { attrs: { name: "unity" } }),
            _c("p", { staticClass: "banner__technologies-title" }, [
              _vm._v("Unity"),
            ]),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "banner__technologies-item" },
          [_c("BaseIcon", { attrs: { name: "bnb-chain" } }), _vm._m(0)],
          1
        ),
        _c(
          "div",
          { staticClass: "banner__technologies-item" },
          [
            _c("BaseIcon", { attrs: { name: "hack-a-chain" } }),
            _c("p", { staticClass: "banner__technologies-title" }, [
              _vm._v("hack-a-chain"),
            ]),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "p",
      {
        staticClass:
          "banner__technologies-title banner__technologies-title--uppercase",
      },
      [
        _c("span", { staticClass: "banner__technologies-title--bold" }, [
          _vm._v("bnb"),
        ]),
        _vm._v(" chain"),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }