// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".tiny-stat {\n  display: flex;\n  align-items: center;\n}\n.tiny-stat__text {\n  color: white;\n  font-size: 12px;\n  text-transform: uppercase;\n  min-width: 25px;\n  margin-right: 4px;\n  line-height: 16px;\n}\n.tiny-stat__progress {\n  transform: skew(4deg);\n  height: 4px;\n}\n.tiny-stat--small .progress-bar {\n  height: 8px;\n  gap: 4px;\n  transform: skew(-2deg);\n}\n.tiny-stat--small .progress-bar__section {\n  gap: 1px;\n}\n.tiny-stat--small__progress {\n  transform: skew(4deg);\n  height: 9px;\n  gap: 5px;\n}\n@media screen and (max-width: 459px) {\n.tiny-stat__text {\n    font-size: 10px;\n    min-width: 20px;\n}\n.tiny-stat--small .progress-bar {\n    gap: 2px;\n    height: 6px;\n}\n}", ""]);
// Exports
exports.locals = {
	"bp_mb_xs": "430px",
	"bp-mb": "640px",
	"bp-laptop": "1630px",
	"bp-desktop": "992px"
};
module.exports = exports;
