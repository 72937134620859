// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".partners {\n  position: relative;\n  z-index: 1;\n  padding: 166px 65px 145px;\n}\n.partners-slider {\n  margin-top: 60px;\n}\n.partners-slider__item {\n  height: 80px;\n  padding: 10px 30px;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n.partners-slider__img {\n  max-width: 100%;\n  max-height: 100%;\n  pointer-events: none;\n}\n@media screen and (max-width: 640px) {\n.partners {\n    padding: 85px 0 90px;\n}\n.partners-slider__item {\n    padding: 0 20px;\n    margin: 10px 0;\n}\n}", ""]);
// Exports
exports.locals = {
	"bp_mb_xs": "430px",
	"bp-mb": "640px",
	"bp-laptop": "1630px",
	"bp-desktop": "992px"
};
module.exports = exports;
