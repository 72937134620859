var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return !_vm.GET_IS_MOBILE
    ? _c(
        "div",
        {
          class: [
            "line",
            {
              [`line--pos-${_vm.position}`]: _vm.position,
            },
          ],
        },
        [
          _vm.animate
            ? _c("div", {
                staticClass: "line__movable-line",
                style: {
                  animationDelay: `${_vm.delay}ms`,
                },
              })
            : _vm._e(),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }