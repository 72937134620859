// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".banner__bg-video {\n  content: \"\";\n  position: absolute;\n  z-index: -1;\n  top: 0px;\n  left: 0;\n  display: block;\n  width: 100%;\n  height: 100%;\n  min-height: 290px;\n  -o-object-fit: cover;\n     object-fit: cover;\n}\n@media screen and (max-width: 991px) {\n.banner__bg-video-wrap {\n    position: relative;\n}\n.banner__bg-video-wrap::after {\n    content: \"\";\n    position: absolute;\n    top: 0;\n    left: 0;\n    width: 100%;\n    height: 100%;\n    background: linear-gradient(0deg, #04091B 3.85%, rgba(4, 9, 27, 0.44) 13.26%, rgba(4, 9, 27, 0) 27.59%);\n}\n.banner__bg-video {\n    position: relative;\n    z-index: 0;\n    min-height: 370px;\n}\n}\n@media screen and (max-width: 639px) {\n.banner__bg-video {\n    height: 100%;\n    -o-object-fit: cover;\n       object-fit: cover;\n}\n}", ""]);
// Exports
exports.locals = {
	"bp_mb_xs": "430px",
	"bp-mb": "640px",
	"bp-laptop": "1630px",
	"bp-desktop": "992px"
};
module.exports = exports;
