<template>
  <div class="fighter-info-card" :class="{ active }">
    <div class="fighter-info-card__header">
      <btn type="play" :text="title" :class="{ active }" />
    </div>
    <div class="fighter-info-card__content">
      <div>
        {{ text }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "FighterInfoCard",
  props: {
    title: { type: String, required: true },
    text: { type: String, required: true },
  },
  data() {
    return {
      active: false,
    }
  },

  mounted() {
    this.initEvents()
  },

  beforeDestroy() {
    this.unsetEvents()
  },

  methods: {
    initEvents() {
      window.addEventListener('blur', this.onBlur)
      this.$el.addEventListener('mouseenter', this.onHover)
      this.$el.addEventListener('mouseleave', this.onBlur)
    },

    unsetEvents() {
      window.removeEventListener('blur', this.onBlur)
      this.$el.removeEventListener('mouseenter', this.onHover)
      this.$el.removeEventListener('mouseleave', this.onBlur)
    },

    onHover() {
      this.active = true
    },

    onBlur() {
      this.active = false
    },
  },
}
</script>

<style lang="scss">

.fighter-info-card {
  position: relative;

  width: 100%;
  max-width: 385px;
  min-height: 160px;
  padding-top: 35px;

  &:before,
  &:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;

    transition: opacity 300ms;
  }

  &:before {
    opacity: 0;
    background: linear-gradient( -22deg, #FF9509, #FFCC13);
  }

  &:after {
    border: 2px solid #D0D0D1;
    background-color: #fff;
  }

  &.active {
    border-color: transparent;
    &:before {
      opacity: 1;
    }

    &:after {
      opacity: 0;
    }
  }
}

.fighter-info-card__header {
  position: absolute;
  top: 0;
  left: 50%;
  margin: auto;
  transform: translate(-50%, -50%);
  
  .button__content {
    text-align: center;
  }
}

.fighter-info-card__content {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 35px;
  font: 500 18px "Tektur";
  text-align: center;
  min-height: 100%;
}

@media screen and (min-width: $bp_mb + 1px) and (max-width: 1200px) {
  .fighter-info-card__content {
    padding: 15px;
  }
}

@media screen and (max-width: $bp_mb) {
  .fighter-info-card {
    max-width: 100%;
    &:after {
      border-width: 4px;
    }
  }
}

</style>
