<template>
  <div
      :class="{'tiny-stat--small': isSmall}"
      class="tiny-stat"
  >
    <div class="tiny-stat__text">
        {{ title }}
    </div>
    <ProgressBarBlocks class="tiny-stat__progress" :count="number" />
  </div>
</template>

<script>
import ProgressBarBlocks from './progress-bar-blocks.vue'

export default {
  components: { ProgressBarBlocks },
  name: "TinyStat",
  props: {
    title: { type: [String, Number], default: '' },
    isSmall: { type: Boolean, default: false },
    number: { type: [String, Number], default: 0 },
  },
}
</script>

<style lang="scss">

.tiny-stat {
    display: flex;
    align-items: center;

    &__text {
        color: white;
        font-size: 12px;
        text-transform: uppercase;

        min-width: 25px;
        margin-right: 4px;
        line-height: 16px;
    }

    &__progress {
        transform: skew(4deg);
        height: 4px;
    }
}

.tiny-stat--small {
    .progress-bar {
        height: 8px;
        gap: 4px;
        transform: skew(-2deg);

        &__section {
            gap: 1px;
        }
    }

    &__progress {
        transform: skew(4deg);
        height: 9px;
        gap: 5px;
    }
}

@media screen and (max-width: 459px) {
    .tiny-stat {
        &__text {
            font-size: 10px;
            min-width: 20px;
        }

        &--small {
            .progress-bar {
                gap: 2px;
                height: 6px;
            }
        }
    }
}

</style>
