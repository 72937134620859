var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "fighters-list" },
    [
      _c(
        "div",
        { staticClass: "fighters-list__bg-image" },
        [
          _c("y-line", { attrs: { position: "1" } }),
          _c("y-line", { attrs: { position: "6" } }),
        ],
        1
      ),
      _c("y-line", { attrs: { position: "1" } }),
      _c("y-line", { attrs: { position: "6" } }),
      _c(
        "div",
        { staticClass: "container" },
        [
          _c(
            "h2",
            {
              staticClass: "section-header section-header--adaptive",
              attrs: { "data-aos": "fade-up", "data-aos-delay": "0" },
            },
            [_vm._v(" Fighters ")]
          ),
          _vm._m(0),
          !_vm.GET_CHARACTERS.length
            ? _c("Loader", { staticClass: "nft-cards--loading" })
            : _c(
                "div",
                { staticClass: "nft-cards container" },
                _vm._l(_vm.GET_CHARACTERS, function (character, i) {
                  return _c(
                    "card-wrapper",
                    _vm._b(
                      {
                        key: `${character.name}_${i}`,
                        staticClass: "card-wrapper",
                        attrs: {
                          contentClass: "fighters-list__content",
                          tier:
                            _vm.CHARACTERS[character.name] &&
                            _vm.CHARACTERS[character.name].tier,
                          "web-images": character.web_images,
                        },
                      },
                      "card-wrapper",
                      character,
                      false
                    ),
                    [
                      _c("template", { slot: "content" }, [
                        _c("div", { staticClass: "nft-card__title" }, [
                          _vm._v(" " + _vm._s(character.name) + " "),
                        ]),
                        _c(
                          "div",
                          { staticClass: "nft-card__stats" },
                          _vm._l(character.stats, function (attribute, i) {
                            return _c(
                              "tiny-stat",
                              _vm._b(
                                {
                                  key: i,
                                  staticClass: "nft-card__stats-item tiny-stat",
                                  attrs: { isSmall: "" },
                                },
                                "tiny-stat",
                                attribute,
                                false
                              )
                            )
                          }),
                          1
                        ),
                      ]),
                    ],
                    2
                  )
                }),
                1
              ),
          _c(
            "div",
            { staticClass: "fighters-list__action" },
            [
              _c("btn", {
                staticClass: "fighters-list__action-btn",
                attrs: {
                  type: "icon-gradient",
                  text: "Go to marketplace",
                  "data-aos": "zoom-in",
                  "data-aos-delay": "200",
                },
                on: {
                  click: function ($event) {
                    return _vm.goToUrl("/marketplace")
                  },
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      {
        staticClass: "description",
        attrs: { "data-aos": "fade-up", "data-aos-delay": "100" },
      },
      [
        _c("span", [
          _vm._v(
            "Get your unique character and customize his characteristics to defeat the enemy."
          ),
        ]),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }