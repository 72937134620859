var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "popup",
        {
          staticClass: "popup--trailer",
          on: { input: _vm.close },
          model: {
            value: _vm.popup,
            callback: function ($$v) {
              _vm.popup = $$v
            },
            expression: "popup",
          },
        },
        [
          _c(
            "div",
            { staticClass: "trailer__video", attrs: { "data-aos": "zoom-in" } },
            [
              _c("youtube-video", {
                attrs: { src: "https://www.youtube.com/watch?v=FqjYc8Tqy7o" },
              }),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }