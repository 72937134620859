<template>
    <div>
        <popup v-model="popup" @input="close" class="popup--trailer">
            <div class="trailer__video" data-aos="zoom-in">
                <youtube-video src="https://www.youtube.com/watch?v=FqjYc8Tqy7o" />
            </div>
        </popup>
    </div>
</template>

<script>
import YoutubeVideo from '../common/youtube-video.vue';

export default {
  name: "TrailerPopup",
  components: {
    YoutubeVideo
  },
  data() {
    return {
      popup: false,
    }
  },
  methods: {
    open(){
      this.popup = true
    },
    close() {
      this.popup = false
    },
  }
}
</script>

<style lang="scss" scoped>
.trailer__video {
  margin: 50px auto 0;
  height: auto;
  width: 73vw;
}

@media screen and (max-width: ($bp_mb - 1px)) {
  .trailer__video {
    margin: 20px auto 0;
    width: 90vw;
  }
}
</style>