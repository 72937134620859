<template>
  <div class="main">
    <Preloader v-if="isLoading" />
    <div v-else>
      <socials type="fixed" />
      <banner />
      <!-- <trailer /> -->
      <fighters />
      <fighters-list/>
      <meta-fights/>
      <partners />
      <vue-coach-marks :marks="marks" v-if="showMarks"/>
    </div>
  </div>
</template>

<script>
import Banner from '@/components/banner/index.vue'
// import Trailer from '@/components/trailer/index.vue'
import Fighters from '@/components/fighters/index.vue'
import FightersList from '@/components/fighters-list/index.vue'
import MetaFights from '@/components/meta-fights/index.vue'
import Partners from '@/components/partners/index.vue'
import VueCoachMarks from '@/components/utils/vue-coach-marks.vue'
import Socials from "@/components/common/socials";
import {mapActions, mapGetters} from "vuex";

export default {
  name: "Main",
  components: {
    Socials,
    Banner,
    // Trailer,
    Fighters,
    MetaFights,
    Partners,
    VueCoachMarks,
    FightersList,
  },
  data() {
    return {
      marks: [
        {
          name: 'main-connect-btn',
          title: 'Beginning',
          text: 'Click on Connect button for authorization and joining Metafighter',
          delay: 500,
          position: 'bottom-center',
        },
        {
          name: 'main-pancakeswap-dextool-btns',
          title: 'Beginning',
          text: 'Buy MF tokens to make purchases on Metafighter',
          delay: 500,
          position: 'top-center',
        },
      ],
      isLoading: false,
      fightersListIsVisible: process.env.NODE_ENV !== 'main',
    }
  },

  computed:{
    ...mapGetters(['GET_CHARACTERS']),

    showMarks(){
      return process.env.NODE_ENV !== 'main';
    }
  },
  async mounted() {
    this.isLoading = true
    await document.fonts.ready

    setTimeout(() => {
        this.isLoading = false
    }, 350)

    if (!this.GET_CHARACTERS.length) {
        await this.REQUEST_CHARACTERS();
    }
  },
  methods: {
    ...mapActions(['REQUEST_CHARACTERS']),
  }
}
</script>

<style lang="scss">
</style>
