<template>
  <div class="meta-fights">
    <fires-canvas :count="100" />
    <y-line class="meta-fights__line meta-fights__line--2" position="1" />
    <y-line class="meta-fights__line meta-fights__line--6" position="6" />

    <i class="meta-fights__bg-image" />
    <div class="meta-fights__inner container">
      <template v-if="!GET_IS_MOBILE">
        <picture>
          <source srcset="@/assets/img/meta-fighters-img-1.webp" type="image/webp">
          <source srcset="@/assets/img/meta-fighters-img-1.png" type="image/png">
          <img
            loading="lazy"
            src="@/assets/img/meta-fighters-img-1.png"
            alt="fighter"
            class="meta-fights__img meta-fights__img--1"
            data-aos="fade-right"
            data-aos-duration="500"
          >
        </picture>
        <picture>
          <source srcset="@/assets/img/meta-fighters-img-2.webp" type="image/webp">
          <source srcset="@/assets/img/meta-fighters-img-2.png" type="image/png">
          <img
            loading="lazy"
            src="@/assets/img/meta-fighters-img-2.png"
            alt="fighter"
            class="meta-fights__img meta-fights__img--2"
            data-aos="fade-left"
            data-aos-delay="100"
          >
        </picture>
      </template>

      <div class="meta-fights__top">
        <h2 class="meta-fights__top-header section-header section-header--adaptive">
          <div
            class="section-header--fz-41"
            data-aos="fade-left"
            data-aos-delay="50"
          >TOKEN UTILITY IN THE</div>
          <span
            class="section-header--gradient section-header--fz-68"
            data-aos="fade-left"
            data-aos-delay="200"
          >metaverse</span>
        </h2>
        <ul class="meta-fights__list">
          <li
            v-for="(item, i) in list"
            :key="i"
            class="meta-fights__list-item"
            data-aos="fade-left"
            :data-aos-delay="50 * i"
          >
            <div
              class="meta-fights__list-media"
              data-aos="zoom-in"
              :data-aos-delay="50 * i + 100"
            >
              <img loading="lazy" :src="require(`../../assets/img/icons/${item.icon}`)" alt="icon">
            </div>

            <div class="meta-fights__list-info">
              <div class="meta-fights__list-info-title">{{ item.title }}</div>
              <div class="meta-fights__list-info-text">{{ item.text }}</div>
            </div>
          </li>

          <!--
          <li
            class="meta-fights__list-item"
            data-aos="fade-left"
          >
            <div
              class="meta-fights__list-media"
              data-aos="zoom-in"
              data-aos-delay="200"
            >
              <img src="@/assets/img/icons/coins.png" alt="coin">
            </div>

            <div class="meta-fights__list-info">
              <div class="meta-fights__list-info-title">Staking</div>
              <div class="meta-fights__list-info-text">
                Temporarily freeze your Fight tokens to get exp boosters, superpowers, or an entire NFT character.
              </div>
            </div>
          </li>
          <li
            class="meta-fights__list-item"
            data-aos="fade-left"
            data-aos-delay="100"
          >
            <div
              class="meta-fights__list-media"
              data-aos="zoom-in"
              data-aos-delay="300"
            >
              <img src="@/assets/img/icons/nft.png" alt="nft">
            </div>

            <div class="meta-fights__list-info">
              <div class="meta-fights__list-info-title">Marketplace</div>
              <div class="meta-fights__list-info-text">
                Mint, sell, buy NFT to participate in battles and tournaments. And for faster pumping, purchase an additional experience booster.
              </div>
            </div>
          </li>
          <li
            class="meta-fights__list-item"
            data-aos="fade-left"
            data-aos-delay="200"
          >
            <div
              class="meta-fights__list-media"
              data-aos="zoom-in"
              data-aos-delay="400"
            >
              <img src="@/assets/img/icons/fighting.png" alt="fighting">
            </div>

            <div class="meta-fights__list-info">
              <div class="meta-fights__list-info-title">Fighting</div>
              <div class="meta-fights__list-info-text">
                Bet tokens on the fight, win and take the whole jackpot for yourself.
              </div>
            </div>
          </li>
          -->
        </ul>
      </div>

      <div class="meta-fights__img-wrapper" v-if="GET_IS_MOBILE">
        <img loading="lazy" src="@/assets/img/meta-fighters-bg-mobile.svg?inline" alt="bg" class="meta-fights__img meta-fights__img--bg">
        <img loading="lazy" src="@/assets/img/meta-fighters-img-1.png" alt="fighter" class="meta-fights__img meta-fights__img--1">
      </div>

      <div class="meta-fights__bottom">
        <h2
          class="meta-fights__bottom-header section-header section-header--adaptive"
          data-aos="fade-up"
          data-aos-delay="0"
        >
          <span class="section-header--gradient section-header--fz-58">GET YOUR $MF</span>
          <div>tokens</div>
        </h2>

        <!-- <div
          class="meta-fights__desc"
          data-aos="fade-up"
          data-aos-delay="100"
        >
          Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium
          doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore
          veritatis et quasi architecto beatae vitae dicta sunt explicabo.
        </div> -->
          <div class="meta-fights__action" data-coach-mark="main-pancakeswap-dextool-btns">
              <btn
                  class="meta-fights__action-btn"
                  type="icon-gradient"
                  icon="pancakeswap-img.png"
                  title="PancakeSwap"
                  data-aos="zoom-in"
                  data-aos-delay="200"
                  @click="goTo(LINKS.PANCAKE)"
              />
          </div>
          <div class="meta-fights__action">
              <btn
                  class="meta-fights__action-btn"
                  type="icon-gradient"
                  icon="dextool.png"
                  title="Dextool"
                  data-aos="zoom-in"
                  data-aos-delay="200"
                  @click="goToBlank(LINKS.DEX)"
              />
          </div>
          <div class="meta-fights__action" data-coach-mark="main-pancakeswap-dextool-btns">
              <btn
                  class="meta-fights__action-btn"
                  type="icon-gradient"
                  icon="digifinex.png"
                  title="Digifinex"
                  data-aos="zoom-in"
                  data-aos-delay="200"
                  @click="goTo(LINKS.DIGIFINEX)"
              />
          </div>
      </div>
    </div>
  </div>
</template>

<script>
import YLine from '@/components/common/y-line.vue'
import { mapGetters } from 'vuex'
import FiresCanvas from '@/components/common/fires-canvas.vue'
import LINKS from '@/constants/Links'

export default {
  name: "MetaFights",
  components: {
    YLine,
    FiresCanvas,
  },
  computed: {
    ...mapGetters([
      'GET_IS_MOBILE',
    ]),
  },
  data() {
    return {
      list: [
        {
          icon: 'stake.png',
          title: 'STAKE',
          text: 'Stake $MF tokens to get exclusive in-game assets with high yield',
        },
        {
          icon: 'earhning.png',
          title: 'IN-GAME EARNING',
          text: 'Earn by trading, renting and lending assets on the marketplace.',
        },
        {
          icon: 'governance.png',
          title: 'Governance',
          text: 'Decide the future of the game as a $MF token holder',
        },
        {
          icon: 'prizes.png',
          title: 'EXCLUSIVE PLAY',
          text: 'Use $MF tokens to enter tournaments and win big prize pools',
        },
        {
          icon: 'token.png',
          title: 'FIGHT TO EARN',
          text: 'Beat opponents to earn $MF tokens',
        },
        {
          icon: 'boost.png',
          title: 'EXP BOOSTER',
          text: 'Hold $MF tokens to level up your fighter faster',
        },
      ],
      LINKS,
    }
  },
  methods: {
    goTo(url) {
      window.open(url, '_blank')
    },
  }
}
</script>

<style lang="scss">

.meta-fights {
  position: relative;
  z-index: 1;

  min-height: 920px;

  color: #fff;
}

.meta-fights__line {
  &--1 {
    mix-blend-mode: overlay;
    opacity: .5;
  }
  &--6 {
    background-color: #292E3D !important;
  }
}

.meta-fights__bg-image {
  @include bg-image-tag-styles('../../assets/img/meta-fighters-bg.png');
  background-color: #04091B;
  background-position: left top;
  background-size: auto;
}

.meta-fights__inner {
  position: relative;
  z-index: 2;
  padding-top: 85px;
  padding-bottom: 95px;
}

.meta-fights__img {
  position: absolute;
  z-index: -1;
  pointer-events: none;
}

.meta-fights__img--1 {
  top: -115px;
  right: 855px;
}

.meta-fights__img--2 {
  right: -75px;
  bottom: -75px;
}

.meta-fights__top {
  max-width: 566px;
  // max-width: 636px;
  margin-left: auto;
}
.meta-fights__top-header {
  text-align: left;
}
.meta-fights__list {
  margin-top: 45px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 40px 20px;
}
.meta-fights__list-item {
  display: flex;
  align-items: flex-start;
  // margin-bottom: 38px;

  // &:last-child {
  //   margin-bottom: 0;
  // }
}
.meta-fights__list-media {
  margin-right: 20px;
  width: 58px;
  min-width: 58px;
  height: 88px;

  display: flex;
  align-items: flex-start;
  justify-content: center;

  img {
    max-width: 100%;
    max-height: 100%;
  }
}

.meta-fights__list-info {
  max-width: 355px;
}
.meta-fights__list-info-title {
  font: 800 21px Raleway;
  text-transform: uppercase;
}
.meta-fights__list-info-text {
  margin-top: 15px;
  font-size: 14px;
}

.meta-fights__bottom {
  padding-top: 130px;
}
.meta-fights__bottom-header {
  line-height: 1;
}
.meta-fights__desc {
  max-width: 540px;
  margin: 20px auto 0;
  text-align: center;
  line-height: 1.5;
  font-size: 14px;
}
.meta-fights__action {
  margin-top: 25px;
  text-align: center;
  margin-top: 20px;
}
.meta-fights__action-btn {
  min-width: 245px;
  margin-bottom: 0.5rem;
}

@media screen and (min-width: $bp_mb + 1px) and (max-width: 1820px) {
  .meta-fights__bg-image {
    background-position: left -100px top;
  }
}

@media screen and (min-width: $bp_mb + 1px) and (max-width: 1580px) {
  .meta-fights__bg-image {
    background-position: left -300px top;
  }
  .meta-fights__img--1 {
    right: 655px;
  }
  .meta-fights__img--2 {
    right: -175px;
  }
}
@media screen and (min-width: $bp_mb + 1px) and (max-width: 1370px) {
  .meta-fights__top {
    // max-width: max-content;
    padding-right: 15px;
  }
  .meta-fights__img--2 {
    right: -275px;
  }
}
@media screen and (min-width: $bp_mb + 1px) and (max-width: 1270px) {
  .meta-fights__bg-image {
    background-position: left top;
    background-size: 50% auto;
  }
  .meta-fights__img--1 {
    right: auto;
    left: 0px;
    width: 420px;
  }
  .meta-fights__img--2 {
    right: -275px;
  }
}
@media screen and (min-width: $bp_mb + 1px) and (max-width: 1170px) {
  .meta-fights__inner {
    padding-bottom: 555px;
  }
  .meta-fights__img--2 {
    left: 0;
    right: 0;
    margin: auto;
  }
}
@media screen and (min-width: $bp_mb + 1px) and (max-width: 955px) {
  .meta-fights__bg-image {
    background-position: left -25% top;
    background-size: 50% auto;
  }
  .meta-fights__img--1 {
    left: -100px;
  }
}

// FIXME REMOVE AFTER
/*
@media screen and (max-width: $bp_desktop) {
  .meta-fights__desc,
  .meta-fights__list-info-text {
    font-size: 18px;
  }
}

@media screen and (max-width: $bp_laptop) {
  .meta-fights__desc,
  .meta-fights__list-info-text {
    font-size: 20px;
  }
}
*/

@media screen and (max-width: $bp_mb) {
  .meta-fights {
    padding: 120px 15px 95px;
    margin-bottom: 0;
  }
  .meta-fights__inner {
    padding-top: 0;
    padding-bottom: 0;
  }
  .meta-fights__bg-image {
    background: #000;
  }

  .meta-fights__top {
    max-width: 100%;
  }

  .meta-fights__top-header {
    text-align: center;
  }

  .meta-fights__list {
    grid-template-columns: 1fr;
  }
  .meta-fights__list-item {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .meta-fights__list-media {
    margin-right: 0;
  }

  .meta-fights__img {
    max-width: 100%;

    &-wrapper {
      margin-top: 98px;
      position: relative;
    }

    &--1 {
      position: static;
    }

    &--bg {
      top: 40px;
      left: -30px;
    }
  }

  .meta-fights__bottom {
    padding-top: 95px;
  }
}

</style>
