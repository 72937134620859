var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "fighters" },
    [
      _c("y-line", { attrs: { position: "1" } }),
      _c("y-line", { attrs: { position: "6" } }),
      _c("div", { staticClass: "container" }, [
        _vm._m(0),
        _c(
          "div",
          {
            staticClass: "fighters__desc",
            attrs: { "data-aos": "fade-up", "data-aos-delay": "100" },
          },
          [
            _vm._v(
              " MetaFighter is a skill-based Play-to-Earn NFT fighting game which captures the essence of arcade fighting classics "
            ),
          ]
        ),
        _c("div", { staticClass: "fighters__cards" }, [
          _c(
            "div",
            {
              staticClass: "fighters__cards-item fighters__cards-item--1",
              attrs: {
                "data-aos": "fade-right",
                "data-aos-delay": "",
                "data-aos-offset": 700 + 50 - 300,
                "data-aos-once": true,
              },
            },
            [
              _c("fighter-info-card", {
                staticClass: "fighters__card",
                attrs: {
                  title: "FIGHTER NFT",
                  text: "Your own fighter upgrade and craft your unique fighter while you master the game.",
                },
              }),
              _vm._m(1),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "fighters__cards-item fighters__cards-item--5",
              attrs: {
                "data-aos": "fade-right",
                "data-aos-delay": "",
                "data-aos-offset": 700 + 50 - 300,
                "data-aos-once": true,
              },
            },
            [
              _c("fighter-info-card", {
                staticClass: "fighters__card",
                attrs: {
                  title: "ARCADE GAMING",
                  text: "No Crypto? No Problem! Just play and have fun, no need for any Blockchain assets.",
                },
              }),
              _vm._m(2),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "fighters__cards-item fighters__cards-item--2",
              attrs: {
                "data-aos": "fade-left",
                "data-aos-delay": "100",
                "data-aos-offset": 700 + 50 - 300,
                "data-aos-once": true,
              },
            },
            [
              _c("fighter-info-card", {
                staticClass: "fighters__card",
                attrs: {
                  title: "FIGHT TO EARN",
                  text: "Get rewards for playing. Win $MF tokens for dominating competition  in tournaments and wager fights.",
                },
              }),
              _vm._m(3),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "fighters__cards-item fighters__cards-item--3",
              attrs: {
                "data-aos": "fade-right",
                "data-aos-delay": "200",
                "data-aos-offset": 400 + 50 - 300,
                "data-aos-once": true,
              },
            },
            [
              _c("fighter-info-card", {
                staticClass: "fighters__card",
                attrs: {
                  title: "ARENA NFT",
                  text: "Your own Business. Scale your business and earn passive income.",
                },
              }),
              _vm._m(4),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "fighters__cards-item fighters__cards-item--4",
              attrs: {
                "data-aos": "fade-left",
                "data-aos-delay": "300",
                "data-aos-offset": 400 + 50 - 300,
                "data-aos-once": true,
              },
            },
            [
              _c("fighter-info-card", {
                staticClass: "fighters__card",
                attrs: {
                  title: "MARKETPLACE",
                  text: "Trade. Rent. Earn. Generate revenues with your assets as you progress.",
                },
              }),
              _vm._m(5),
            ],
            1
          ),
        ]),
        _c(
          "div",
          {
            staticClass: "fighters__persons",
            attrs: { "data-aos": "fade-up", "data-aos-offset": 400 - 300 },
          },
          [
            !_vm.GET_IS_MOBILE
              ? _c(
                  "div",
                  {
                    staticClass:
                      "fighters__persons-item fighters__persons-item--1",
                  },
                  [_vm._m(6)]
                )
              : _vm._e(),
            _vm._m(7),
            _vm._m(8),
            _vm._m(9),
            !_vm.GET_IS_MOBILE
              ? _c(
                  "div",
                  {
                    staticClass:
                      "fighters__persons-item fighters__persons-item--5",
                  },
                  [_vm._m(10)]
                )
              : _vm._e(),
          ]
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "h2",
      {
        staticClass: "section-header section-header--adaptive",
        attrs: { "data-aos": "fade-up", "data-aos-delay": "0" },
      },
      [
        _vm._v(" A FIGHTING GAME"),
        _c("br"),
        _c(
          "span",
          { staticClass: "section-header--fz-58 section-header--gradient" },
          [_vm._v("METAVERSE")]
        ),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      {
        staticClass: "fighters__cards-line fighters__cards-line--1",
        attrs: { "data-aos": "fade-down-right", "data-aos-delay": "50" },
      },
      [_c("i")]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      {
        staticClass: "fighters__cards-line fighters__cards-line--5",
        attrs: { "data-aos": "fade-down-right", "data-aos-delay": "50" },
      },
      [_c("i")]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      { attrs: { "data-aos": "fade-down-left", "data-aos-delay": "150" } },
      [
        _c(
          "div",
          { staticClass: "fighters__cards-line fighters__cards-line--2" },
          [_c("i")]
        ),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      {
        staticClass: "fighters__cards-line fighters__cards-line--3",
        attrs: { "data-aos": "fade-down-right", "data-aos-delay": "250" },
      },
      [_c("i")]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      {
        staticClass: "fighters__cards-line fighters__cards-line--4",
        attrs: { "data-aos": "fade-down-left", "data-aos-delay": "350" },
      },
      [_c("i")]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("picture", [
      _c("source", {
        attrs: {
          srcset: require("@/assets/img/fighters/meta_1.webp"),
          type: "image/webp",
        },
      }),
      _c("source", {
        attrs: {
          srcset: require("@/assets/img/fighters/meta_1.png"),
          type: "image/png",
        },
      }),
      _c("img", {
        attrs: {
          loading: "lazy",
          src: require("@/assets/img/fighters/meta_1.png"),
          alt: "warior",
        },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      { staticClass: "fighters__persons-item fighters__persons-item--2" },
      [
        _c("picture", [
          _c("source", {
            attrs: {
              srcset: require("@/assets/img/fighters/meta_2.webp"),
              type: "image/webp",
            },
          }),
          _c("source", {
            attrs: {
              srcset: require("@/assets/img/fighters/meta_2.png"),
              type: "image/png",
            },
          }),
          _c("img", {
            attrs: {
              loading: "lazy",
              src: require("@/assets/img/fighters/meta_2.png"),
              alt: "warior",
            },
          }),
        ]),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      { staticClass: "fighters__persons-item fighters__persons-item--3" },
      [
        _c("picture", [
          _c("source", {
            attrs: {
              srcset: require("@/assets/img/fighters/meta_3.webp"),
              type: "image/webp",
            },
          }),
          _c("source", {
            attrs: {
              srcset: require("@/assets/img/fighters/meta_3.png"),
              type: "image/png",
            },
          }),
          _c("img", {
            attrs: {
              loading: "lazy",
              src: require("@/assets/img/fighters/meta_3.png"),
              alt: "warior",
            },
          }),
        ]),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      { staticClass: "fighters__persons-item fighters__persons-item--4" },
      [
        _c("picture", [
          _c("source", {
            attrs: {
              srcset: require("@/assets/img/fighters/meta_4.webp"),
              type: "image/webp",
            },
          }),
          _c("source", {
            attrs: {
              srcset: require("@/assets/img/fighters/meta_4.png"),
              type: "image/png",
            },
          }),
          _c("img", {
            attrs: {
              loading: "lazy",
              src: require("@/assets/img/fighters/meta_4.png"),
              alt: "warior",
            },
          }),
        ]),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("picture", [
      _c("source", {
        attrs: {
          srcset: require("@/assets/img/fighters/meta_5.webp"),
          type: "image/webp",
        },
      }),
      _c("source", {
        attrs: {
          srcset: require("@/assets/img/fighters/meta_5.png"),
          type: "image/png",
        },
      }),
      _c("img", {
        attrs: {
          loading: "lazy",
          src: require("@/assets/img/fighters/meta_5.png"),
          alt: "warior",
        },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }