// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".icon-btn {\n  display: inline-flex;\n  align-items: center;\n  justify-content: center;\n  border-radius: 50%;\n  border: 2px solid transparent;\n  transition: background-color 300ms;\n}\n.icon-btn:after {\n  content: \"\";\n  position: absolute;\n  top: -2px;\n  left: -2px;\n  right: -2px;\n  bottom: -2px;\n  z-index: -1;\n  border-radius: inherit;\n  background: linear-gradient(to right, #FF9509, #FFCC13);\n  opacity: 1;\n  transition: opacity 300ms;\n}\n.icon-btn:hover {\n  background-color: #fff;\n}\n.icon-btn:hover:after {\n  opacity: 0;\n}\n.icon-btn__icon {\n  max-width: 45%;\n}\n.icon-btn__icon--play {\n  transform: translateX(18%);\n}", ""]);
// Exports
exports.locals = {
	"bp_mb_xs": "430px",
	"bp-mb": "640px",
	"bp-laptop": "1630px",
	"bp-desktop": "992px"
};
module.exports = exports;
